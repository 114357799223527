import React, { Component, Fragment } from 'react';
import TableWithPagination from './common/TableWithPagination';
import { Button, Row, Col } from 'react-bootstrap'
import { getAllTapeBP } from '../services/baseplateService';
import Icons from './common/Icons';
import BaseplateEditTape from './BaseplateEditTape';
import ProtectedComponent from './common/ProtectedComponent';
import { AuthContext } from './Login/AuthContext';
import { Link } from 'react-router-dom';
import { NiceBoolean } from './common/Common';
import { getPrettyBkgColor, isDef} from '../services/commonService';

class BaseplateTape extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);
    this.state = {
      data : [],
      editmodals: [],
    }
  }

  columns = [
    {
      Header:'Barcode',
      accessor: 'bar_code',
      Cell: (props) => {
        let id = props.row.original.id;
        return (
        <Fragment>
          <Button 
            variant='light' 
            size='sm'
            onClick={()=>{
              let modals = this.state.editmodals;
              modals[id] = true;
              this.setState({editmodals:modals}); 
            }}
            >
            {Icons.edit}
            </Button>
            {props.cell.value}

            {this.state.editmodals[id] && (
              <BaseplateEditTape 
                isOpen={this.state.editmodals[id]}
                id={id}
                username={this.context.user.username}
                callback={(e) => {
                  let {editmodals} = this.state;
                  editmodals[id] = e;
                  this.setState({editmodals});
                }}
                callbackbps={(e, form) => {
                  if(form.tape_id!==null){
                    let new_data = this.state.data;
                    const filteredData = new_data.filter(item => item.id !== e);
                    this.setState({ data: [...filteredData] });
                  }else{
                    let new_data = this.state.data;
                    new_data[props.row.index]['bar_code'] = form.bar_code;
                    this.setState({ data: [...new_data] });
                  }

                }}
              />
              )}
        </Fragment>
        );
      }
    },
    {
      Header:'Workshop serial',
      accessor: 'workshop_serial',
      getCellStyle: () => ({
        textAlign: 'center'
      }),
      Cell: (props) => {
        return (props.cell.value!==null && props.cell.value!=='')&& String(props.cell.value).padStart(6, '0');
      }
    },
    {
      Header:'Kapton align passed',
      accessor: 'kapton_align_passed',
      Cell: (props) => {
        return(
          <NiceBoolean value={props.cell.value} />
        );
      }
    },
    {
      Header:'Laminated flatness grade',
      accessor: 'flatness_lam_grade',
      getCellStyle: (value) => ({
        backgroundColor: isDef(value) && getPrettyBkgColor(value),
        textAlign: 'center'
      }),
    },
    {
      Header:'Bare flatness grade',
      accessor: 'flatness_bare_grade',
      getCellStyle: (value) => ({
        backgroundColor: isDef(value) && getPrettyBkgColor(value),
        textAlign: 'center'
      }),
    },
    {
      Header:'Tolerance grade',
      accessor: 'tolerance_grade',
      getCellStyle: (value) => ({
        backgroundColor: isDef(value) && getPrettyBkgColor(value),
        textAlign: 'center'
      }),
    },
  ]
  
  async componentDidMount(){
    // this.state.data = await getAllBP();
    let mydata = await getAllTapeBP();
    let modals = [];
    mydata.forEach(item => {
      modals[item['id']] = false;  
    })
    this.setState({data:mydata, editmodals:modals});
  }

  render(){
    const { user } = this.context;

    return (
      <Fragment>
        <ProtectedComponent>
          {
            user?.role==='public' && 
            <h4>This page is not public. Please contact the admin to approve access.</h4>
          }
          {
            user?.role!=='public' && 
            <Fragment>
              <Row>
                <Col>
                  <h1>Baseplates Tape</h1>
                </Col>
                <Col style={{textAlign: 'right'}}>
                  <Row>
                    <Link to="/baseplate-list">
                      Back to main list
                    </Link>
                  </Row>
                  <Row>
                    <Link to="/baseplate-lamination-list">
                      Lamination view
                    </Link>
                  </Row>
                </Col>
              </Row>                   
              <TableWithPagination 
                columns={this.columns}
                data={this.state.data}
                defaultPageSize={50}
              />
            </Fragment>
          }
        </ProtectedComponent>
      </Fragment>
    );
  }
}

export default BaseplateTape;