import React, { Component, Fragment } from 'react';
import Icons from './common/Icons';
import ProtectedComponent from './common/ProtectedComponent';

class MaterialList extends Component {
  constructor(props){
    super(props);
    this.state = {
      glues : {}
    }
  }
  
  render(){
    return (
      <Fragment>
        <ProtectedComponent>
          <h1>Material List</h1>
          <div>Under development {Icons.construction}</div>
        </ProtectedComponent>
      </Fragment>
    );
  }
}

export default MaterialList;