import { notify } from './notificationService';

export function isDef(obj) {
	return typeof obj !== 'undefined';
}

export function validResponse(data) {
	return data && typeof data == 'object';
}

export function notifyResult({ data, success, error }, type = 'object') {
  let isValid = data && data !== '';
  if (type === 'object') {
    isValid = validResponse(data);
  }
  isValid ? notify(success, { type: 'success' }) : notify(error, { type: 'error' });
  return isValid;
}



export function notifyResultWithTime({ data, success, error }, type = 'object') {
  let isValid = data && data !== '';
  if (type === 'object') {
    isValid = validResponse(data);
  }
  isValid
    ? notify(success, { type: 'success', autoClose: 2000 })
    : notify(error, { type: 'error', autoClose: false });
  return isValid;
}

export function isValidAlphanumeric(input) {
  const alphanumericRegex = /^[a-zA-Z0-9]+$/;
  return alphanumericRegex.test(input);
}

export function isValidDecimal(input) {
  const decimalRegex = /^[+-]?(\d+(\.\d*)?|\.\d+)$/;
  return decimalRegex.test(input);
}

// Function to get the current date in ddmmyy format
export function getCurrentDateDDMMYY() {
  const date = new Date();

  // Extract day, month, and year
  const day = String(date.getDate()).padStart(2, '0'); // Ensure two digits
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const year = String(date.getFullYear()).slice(-2); // Get last two digits of the year

  return `${day}${month}${year}`;
}