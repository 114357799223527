import React, { useState, useContext, Fragment } from 'react';
import { AuthContext } from './AuthContext';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { API_BASE } from '../../services/apiService';

const Login = () => {
  const { login } = useContext(AuthContext);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${API_BASE}/login`, { username, password });
      login(response.data.token); // Save token and decode user info
      navigate("/");
    } catch (err) {
      setError('Invalid credentials');
    }
  };

  return (
    <Fragment>
      <form onSubmit={handleSubmit}>
        <h4>Login</h4>
        <input type="username" value={username} onChange={(e) => setUsername(e.target.value)} placeholder="Username" required />
        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" required />
        <button type="submit">Login</button>
        {error && <p>{error}</p>}
      </form>
      <br />
      or <Link to="/signup">Sign up</Link>
    </Fragment>
  );
};

export default Login;
