import React from 'react';
import './App.css';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter as Router, Routes, Route, useParams } from 'react-router-dom';
import NavbarComponent from './components/common/NavbarComponent';
import GlueList from './components/GlueList';
import Home from './components/Home';
import MaterialList from './components/MaterialList';
import TapeList from './components/TapeList';
import UserList from './components/UserList';
import BaseplateList from './components/BaseplateList';
import SignUp from './components/Login/Signup';
import Login from './components/Login/Login';
import BaseplateLam from './components/BaseplateLam';
import BaseplateMedia from './components/BaseplateMedia';

let basename = process.env.REACT_APP_BASENAME ? process.env.REACT_APP_BASENAME : 'hgcal.etp.kit.edu';

function App() {  
  return (
    <Router basename={'/'}>
      <div>
        <ToastContainer style={{width:'auto', maxWidth:'2000px', wordBreak:'break-all'}} />
        <header className="fixed-top">
          <NavbarComponent />
        </header>
        <section>
          <div className='container-fluid p-3'>
            <Routes>
              <Route path="/glue-list" Component={GlueList} />
              <Route path="/material-list" Component={MaterialList} />
              <Route path="/tape-list" Component={TapeList} />
              <Route path="/user-list" Component={UserList} />
              <Route path="/baseplate-list" Component={BaseplateList} />
              <Route path="/baseplate-lamination-list" Component={BaseplateLam} />
              <Route path="/baseplate-media/:id" element={<BaseplateMediaWrapper />} />

              <Route path="/signup" element={<SignUp />} />
              <Route path="/login" element={<Login />} />
              <Route exact path='/' Component={() => (<Home />)} />
              
            </Routes>
          </div>
        </section>
      </div>
    </Router>
  );
}

// Wrapper to pass useParams to a class component
const BaseplateMediaWrapper = () => {
  const { id } = useParams();
  return <BaseplateMedia id={id} />;
};

export default App;


