import React, { Component, Fragment } from 'react';
import { Button, Modal, Form, Row, Col, Spinner } from 'react-bootstrap'
import { getBP, updateBP } from '../services/baseplateService';
import { isDef, isValidAlphanumeric } from '../services/commonService';
import { getSummaryTape } from '../services/tapeService';
import Select from "react-select";

class BaseplateEditTape extends Component {
  constructor(props){
    super(props);
    this.state = {
      username : props.username,
      userrole : props.userrole,
      loading : true,
      isOpen: props.isOpen,
      id: props.id,
      data: {},
      tape_options : {},
      formEdit: {
        tape_id: null,
        bar_code: null
      },
      isModified: false,
      errors:{}
    }
  }

  async componentDidMount(){
    if(this.state.isOpen){
      let data = await getBP(this.state.id);
      let topt = await getSummaryTape();
      if(data){
        this.setState({data: data[0], tape_options: topt, loading:false }); 
      }
    }
  }

  closeModal = () => {
		this.resetForm();
		isDef(this.props.callback) && this.props.callback(false);
	};

  resetForm = () =>{
		let {formEdit} = this.state;
    formEdit= {
      tape_id: null,
      bar_code: null,
    };
		this.setState({formEdit, isModified:false});
	}

  changeFormEdit(e, field){
    let {formEdit, data} = this.state;
    formEdit[field] =  e.target.value;
    data[field] = e.target.value;
    this.setState({formEdit, data, isModified:true }); 
  }

  render(){
    if (this.state.loading) return <Spinner animation="border" variant="info" />
    let {formEdit,data,isOpen, errors} = this.state;

    return (
      <Fragment>
        <Modal
          show={isOpen} 
          onHide={() => {
            this.setState({ isOpen:false });  
          } }
          animation={false}
          size='xl'
          aria-labelledby="contained-modal-title-vcenter"
          scrollable
          centered
        >
          <Modal.Header>
            <Modal.Title>
              Edit baseplate {data.bar_code === null ? data.workshop_serial : data.bar_code}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form style={{ minWidth:'2000px'}}>
              <Row>
                <Form.Label column sm={1}>
                  BARCODE
                </Form.Label>
                <Col sm={2}>
                  <Form.Control 
                    type="text" 
                    value={data['bar_code'] ? data['bar_code'] : ''} 
                    onChange={(e)=>{
                      //validate value
                      if(isValidAlphanumeric(e.target.value)){
                        formEdit['bar_code'] = e.target.value;
                        data['bar_code'] = e.target.value;
                        errors['bar_code'] = null;
                        this.setState({ formEdit, data, errors, isModified:true });
                      }else{
                        errors['bar_code'] = "only alphanumerical";
                        this.setState({errors});
                      }
                    }}
                    isInvalid={!!errors['bar_code']}
                  />
                  <Form.Control.Feedback type="invalid">
                    {this.state.errors['bar_code']}
                  </Form.Control.Feedback>
                </Col>
              </Row>  
              <br />
              <Row>
                <Form.Label column sm={1}>
                  TAPE
                </Form.Label>
                <Col sm={2}>
                  <Select 
                    value={{value:data.tape_id, label: data.tape_description}} 
                    onChange={async(e) => {
                      formEdit['tape_id'] = e.value;
                      data['tape_id'] = e.value;
                      data['tape_description'] = e.label;
                      this.setState({data, formEdit, isModified:true});
                    }}
                    options={this.state.tape_options}
                  />
                </Col>
              </Row>  
            </Form>
          </Modal.Body>
          <Modal.Footer>            
            <Button 
              variant="secondary"
              onClick={() => this.state.isModified ? ((window.confirm('Close without saving?')) && this.closeModal()) : this.closeModal() }
            >
              Close
            </Button>
            <Button 
              variant="primary" 
              disabled={!this.state.isModified}
              onClick={async () => {
                let updated_bp = await updateBP(this.state.id, this.state.formEdit, this.state.username);   
                if(updated_bp){
                  //remove bp from list since kapton* glue* is not null
                  isDef(this.props.callbackbps) && this.props.callbackbps(this.state.id, this.state.formEdit);
                  this.resetForm();
                }
                this.closeModal();
              }}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    );
  }
}

export default BaseplateEditTape;