import api, {API_BASE} from './apiService'
import axios from 'axios';

export async function getAllUsers() {
  const { data } = await api.get(`${API_BASE}/list-users`, { params:{} });
  return data;
}

export async function addUser(username, role) {
  const { data } = await api.post(`${API_BASE}/add-user`, { username, role });
  return data;
}

export async function deleteUser(username) {
  const response = await axios
  .delete(`${API_BASE}/delete-user/${username}`, {data:{username}, headers: { "Cache-Control": "no-cache" }})
  .catch((error) => console.log("Error: ", error));
  if (response && response.data) {
    return response.data;
  }
}

export async function updateUser(username, role) {
  const response = await axios
  .put(`${API_BASE}/update-user/${username}`, {username, role, headers: { "Cache-Control": "no-cache" }})
  .catch((error) => console.log("Error: ", error));
  if (response && response.data) {
    return response.data;
  }
}

export async function getUser(username) {
  const { data } = await api.get(`${API_BASE}/get-user/${username}`, {data:{username}});
  return data;
}


export async function signupUser(username, password) {
  const { data } = await api.post(`${API_BASE}/signup`, { username, password });
  return data;
}

export async function loginUser(username, password) {
  const { data } = await api.get(`${API_BASE}/login`, { username, password });
  console.log(data)
  return data;
}