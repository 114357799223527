import React, { Component, Fragment } from 'react';
import { Button, Modal, Form, Row, Col, Spinner } from 'react-bootstrap'
import { getBP, updateBP } from '../services/baseplateService';
import Icons from './common/Icons';
import { isValidDecimal, isDef, isValidAlphanumeric } from '../services/commonService';
import { getSummaryKapton } from '../services/kaptonService';
import { getSummaryTape } from '../services/tapeService';
import { getSummaryMaterial } from '../services/materialService';
import { getSummaryGlue } from '../services/glueService';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";

class BaseplateEdit extends Component {
  constructor(props){
    super(props);
    this.state = {
      username : props.username,
      userrole : props.userrole,
      loading : true,
      isOpen: props.isOpen,
      id: props.id,
      data: {},
      kapton_options : {},
      tape_options : {},
      glue_options : {},
      material_options : {},
      formEdit: {
        weight_bare: null,
        weight_lam: null,
        thickness: null,
        kapton_align_passed: null,
        comment: null,
        material_id: null,
        tape_id: null,
        kapton_id: null,
        glue_id: null,
        lam_user: null,
        shipment_date: null
      },
      isModified: false,
      errors:{}
    }
  }

  async componentDidMount(){
    if(this.state.isOpen){
      let data = await getBP(this.state.id);
      let kopt = await getSummaryKapton();
      let topt = await getSummaryTape();
      let mopt = await getSummaryMaterial();
      let gopt = await getSummaryGlue();
      if(data){
        this.setState({data: data[0], kapton_options: kopt, tape_options: topt, material_options: mopt, glue_options: gopt, loading:false }); 
      }
    }
  }

  closeModal = () => {
		this.resetForm();
		isDef(this.props.callback) && this.props.callback(false);
	};

  resetForm = () =>{
		let {formEdit} = this.state;
    formEdit= {
      bar_code: null,
      weight_bare: null,
      weight_lam: null,
      thickness: null,
      kapton_align_passed: null,
      comment: null,
      material_id: null,
      tape_id: null,
      kapton_id: null,
      glue_id: null,
      lam_user: null,
      shipment_date: null
    };
		this.setState({formEdit, isModified:false});
	}

  changeFormEdit(e, field){
    let {formEdit, data} = this.state;
    formEdit[field] =  e.target.value;
    data[field] = e.target.value;
    this.setState({formEdit, data, isModified:true }); 
  }

  decimalFormEdit(field, label){ 
    let {data, errors, formEdit} = this.state; 
    return(
      <Fragment key={`${field}field`}>
        <Row>
          <Form.Label column sm={2}>
            {label}
          </Form.Label>
          <Col sm={2}>
            <Form.Control 
              type="text" 
              value={data[field] ? data[field] : ''} 
              onChange={(e)=>{
                //validate value
                if(isValidDecimal(e.target.value)){
                  formEdit[field] = e.target.value;
                  data[field] = e.target.value;
                  errors[field] = null;
                  this.setState({ formEdit, data, errors, isModified:true });
                }else{
                  errors[field] = "only decimal";
                  this.setState({errors});
                }
              }}
              isInvalid={!!errors[field]}
            />
            <Form.Control.Feedback type="invalid">
              {this.state.errors[field]}
            </Form.Control.Feedback>
          </Col>
        </Row>
      </Fragment>
    )
  }


  boolFormEdit(field, label){
    let {data} = this.state;
    return(
      <Fragment key={`${field}boolean`}>
        <Row>
          <Form.Label column sm={2}>
            {label}
          </Form.Label>
          <Col sm={2}>
            <Form.Select 
              value={data[field] ? data[field] : "null"} 
              onChange={(e)=>{
                let {formEdit} = this.state;
                formEdit[field] =  e.target.value;
                data[field] = e.target.value; 
                this.setState({ formEdit, data, isModified:true }); 
              }}  
            >
              <option value="null">{Icons.empty}</option>
              <option value="true">{Icons.ok}</option>
              < option value="false">{Icons.wrong}</option>
            </Form.Select>
          </Col>
        </Row>  
      </Fragment>
    )
  }
  

  render(){
    if (this.state.loading) return <Spinner animation="border" variant="info" />
    let {formEdit,data,isOpen, errors} = this.state;

    return (
      <Fragment>
        <Modal
          show={isOpen} 
          onHide={() => {
            this.setState({ isOpen:false });  
          } }
          animation={false}
          size='xl'
          aria-labelledby="contained-modal-title-vcenter"
          scrollable
          centered
        >
          <Modal.Header>
            <Modal.Title>
              Edit baseplate {data.bar_code === null ? data.workshop_serial : data.bar_code}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form style={{ minWidth:'2000px'}}>
              <Row>
                <Form.Label column sm={2}>
                  BARCODE
                </Form.Label>
                <Col sm={2}>
                  <Form.Control 
                    type="text" 
                    value={data['bar_code'] ? data['bar_code'] : ''} 
                    onChange={(e)=>{
                      //validate value
                      if(isValidAlphanumeric(e.target.value)){
                        formEdit['bar_code'] = e.target.value;
                        data['bar_code'] = e.target.value;
                        errors['bar_code'] = null;
                        this.setState({ formEdit, data, errors, isModified:true });
                      }else{
                        errors['bar_code'] = "only alphanumerical";
                        this.setState({errors});
                      }
                    }}
                    isInvalid={!!errors['bar_code']}
                  />
                  <Form.Control.Feedback type="invalid">
                    {this.state.errors['bar_code']}
                  </Form.Control.Feedback>
                </Col>
              </Row>  
              <br />
              {this.decimalFormEdit('weight_bare', 'WEIGHT BARE')}
              <br />
              {this.decimalFormEdit('thickness', 'THICKNESS')}
              <br />
              <Row>
                <Form.Label column sm={2}>
                  MATERIAL
                </Form.Label>
                <Col sm={2}>
                  <Select 
                    value={{value:data.material_id, label: data.material_description}} 
                    onChange={async(e) => {
                      formEdit['material_id'] = e.value;
                      data['material_id'] = e.value;
                      data['material_description'] = e.label;
                      this.setState({data, formEdit, isModified:true});
                    }}
                    options={this.state.material_options}
                  />
                </Col>
              </Row>  
              <br />
              <Row>
                <Form.Label column sm={2}>
                  TAPE
                </Form.Label>
                <Col sm={2}>
                  <Select 
                    value={{value:data.tape_id, label: data.tape_description}} 
                    onChange={async(e) => {
                      formEdit['tape_id'] = e.value;
                      data['tape_id'] = e.value;
                      data['tape_description'] = e.label;
                      this.setState({data, formEdit, isModified:true});
                    }}
                    options={this.state.tape_options}
                  />
                </Col>
              </Row>  
              <br />
              <Row>
                <Form.Label column sm={2}>
                  KAPTON
                </Form.Label>
                <Col sm={2}>
                  <Select 
                    value={{value:data.kapton_id, label: data.kapton_description}} 
                    onChange={async(e) => {
                      formEdit['kapton_id'] = e.value;
                      data['kapton_id'] = e.value;
                      data['kapton_description'] = e.label;
                      this.setState({data, formEdit, isModified:true});
                    }}
                    options={this.state.kapton_options}
                  />
                </Col>
              </Row>  
              <br />
              <Row>
                <Form.Label column sm={2}>
                  GLUE
                </Form.Label>
                <Col sm={2}>
                  <Select 
                    value={{value:data.glue_id, label: data.epoxy_expiry_date}} 
                    onChange={async(e) => {
                      formEdit['glue_id'] = e.value;
                      data['glue_id'] = e.value;
                      data['epoxy_expiry_date'] = e.label;
                      this.setState({data, formEdit, isModified:true});
                    }}
                    options={this.state.glue_options}
                  />
                </Col>
              </Row>  
              <br />           
              {this.decimalFormEdit('weight_lam', 'WEIGHT LAMINATION')}
              <br />
              {this.boolFormEdit('kapton_align_passed','KAPTON ALIGN PASSED')}
              <br />
              <Row>
                <Form.Label column sm={2}>
                  SHIPMENT DATE
                </Form.Label>
                <Col sm={3}>
                  <DatePicker 
                    className='form-control form-control-solid w-250px '
                    selected={this.state.formEdit['shipment_date']} 
                    disabled={this.state.userrole!=='admin'}
                    onChange={(date) => {
                      // let {formEdit, errors} = this.state;
                      formEdit['shipment_date'] = date;
                      errors['shipment_date'] = null;
                      this.setState({ formEdit, errors, isModified:true });
                    }} 
                  />
                </Col>
              </Row> 
              <br />
              <Row>
                <Form.Label column sm={2}>
                  COMMENT
                </Form.Label>
                <Col sm={3}>
                  <Form.Control 
                    as="textarea" 
                    rows={3}
                    value={data['comment']}
                    onChange={(e)=>{this.changeFormEdit(e,'comment')}}
                    />
                </Col>
              </Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>            
            <Button 
              variant="secondary"
              onClick={() => this.state.isModified ? ((window.confirm('Close without saving?')) && this.closeModal()) : this.closeModal() }
            >
              Close
            </Button>
            <Button 
              variant="primary" 
              disabled={!this.state.isModified}
              onClick={async () => {
                let updated_bp = await updateBP(this.state.id, this.state.formEdit, this.state.username);   
                if(updated_bp){
                  updated_bp['material_description'] = data['material_description'];
                  updated_bp['kapton_description'] = data['kapton_description'];
                  updated_bp['tape_description'] = data['tape_description'];
                  updated_bp['epoxy_expiry_date'] = data['epoxy_expiry_date'];

                  isDef(this.props.callbackbps) && this.props.callbackbps(updated_bp);
                  this.resetForm();
                }
                this.closeModal();
              }}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    );
  }
}

export default BaseplateEdit;