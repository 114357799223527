import React, { Component, Fragment } from 'react';
import TableWithPagination, {SelectColumnFilter, SelectColumnFilterBoolean} from './common/TableWithPagination';
import { Button, ListGroup, ListGroupItem, Row, ButtonGroup, Col, Accordion , Form} from 'react-bootstrap'
import { getAllBP, deleteBP} from '../services/baseplateService';
import Icons from './common/Icons';
import BaseplateEdit from './BaseplateEdit';
import ProtectedComponent from './common/ProtectedComponent';
import { AuthContext } from './Login/AuthContext';
import { Link } from 'react-router-dom';
import { NiceBoolean } from './common/Common';
import { getPrettyBkgColor, isDef} from '../services/commonService';
import BaseplateAdd from './BaseplateAdd';
import { API_BASE } from '../services/apiService';
import { createXMLnewpartsfilter, createXMLqcgradefilter, updateBPshipment } from '../services/baseplateService';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class BaseplateList extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);
    this.state = {
      loading:true,
      data : [],
      baseplates: [],
      showUnexported: false,
      selected: [],
      errors:{},
      editmodals: [],
      showAdd: false,
      barcode_txt : null,
      exportForm:{
        barcode_list : null,
        shipment_date: new Date(),
        comment: null
      }
    }
  }

  columns = [
    {
      Header:'Type',
      minWidth: 120,
      Filter: SelectColumnFilter,
      accessor:'bp_type',
    },
    {
      Header:'Barcode',
      minWidth: 270,
      accessor: 'bar_code',
      filter: 'fuzzyText',
      Cell: (props) => {
        let id = props.row.original.id;

        return(
          <Fragment>
            {props.cell.value}
            {this.state.editmodals[id] && 
              <BaseplateEdit 
                isOpen={this.state.editmodals[id]}
                id={id}
                key={`editmodal-${id}`}
                username={this.context.user.username}
                userrole={this.context.user.role}
                callback={(e) => {
                  let {editmodals} = this.state;
                  editmodals[id] = e;
                  this.setState({editmodals});
                }}
                callbackbps={(e) => {
                  let new_data = this.state.data;
                  new_data[props.row.index] = e;
                  this.setState({ data: [...new_data] });
                }}
              />
            }
            <ButtonGroup aria-label="Bp actions">
              <Link to={`/baseplate-media/${id.toString().padStart(5, '0')}`} disabled>
                <Button
                  variant='light' 
                  size='sm'  
                >
                  {Icons.folder}
                </Button>
              </Link>
              <Button 
                variant='light' 
                size='sm'
                onClick={()=>{
                  let modals = this.state.editmodals;
                  modals[id] = true;
                  this.setState({editmodals:modals}); 
                }}
                >
                {Icons.edit}
              </Button>
              {this.context.user?.role==='admin' && 
                <Button 
                  variant='light' 
                  size='sm'
                  onClick= {async () => {
                    let deleted_id = await deleteBP(id); 
                    let new_data =  this.state.data.filter(item => item.id!==deleted_id.id)
                    this.setState({data:new_data}); 
                  }}
                >
                  {Icons.remove}
                </Button>
              }

            </ButtonGroup>
          </Fragment>
        );
      }
    },
    {
      Header:'Workshop serial',
      accessor: 'workshop_serial',
      Cell: (props) => {
        return (props.cell.value!==null && props.cell.value!=='') && String(props.cell.value).padStart(6, '0');
      }
    },
    {
      Header:'Weight bare',
      accessor: 'weight_bare'
    },
    {
      Header:'Weight lamination',
      accessor: 'weight_lam'
    },
    {
      Header:'Thickness',
      accessor: 'thickness',
      getCellStyle: (value, row) => ({
        backgroundColor: isDef(row['thickness_grade']) && getPrettyBkgColor(row['thickness_grade']),
      }),
      
    },
    {
      Header:'Height bare',
      accessor: '',
      Cell: (props) => {
        let original_row = props.cell.row.original;
        const listItems = [];
        for (let i = 0; i <= 19; i++) {
          listItems.push(<ListGroupItem key={`height_bare${i}`}>{original_row[`height_bare${i}`]}</ListGroupItem>);
        }
        return(
          <Fragment>
            <ListGroup>              
              {listItems}
            </ListGroup>
          </Fragment>
        );
      }
    },   
    {
      Header:'Flatness bare left',
      accessor: 'flatness_bare_l',
      getCellStyle: (value, row) => ({
        backgroundColor: isDef(row.flatness_bare_grade) && getPrettyBkgColor(row.flatness_bare_grade),
      })
    },
    {
      Header:'Flatness bare right',
      accessor: 'flatness_bare_r',
      getCellStyle: (value, row) => ({
        backgroundColor: isDef(row.flatness_bare_grade) && getPrettyBkgColor(row.flatness_bare_grade),
      }),
    },
    {
      Header:'Height lam',
      accessor: '',
      Cell: (props) => {
        let original_row = props.cell.row.original;
        const listItems = [];
        for (let i = 0; i <= 9; i++) {
          listItems.push(<ListGroupItem key={`height_lam${i}`}>{original_row[`height_lam${i}`]}</ListGroupItem>);
        }
        return(
          <Fragment>
            <ListGroup>              
              {listItems}
            </ListGroup>
          </Fragment>
        );
      }
    },   
    {
      Header:'Flatness lam',
      accessor: 'flatness_lam',
      getCellStyle: (value, row) => ({
        backgroundColor: isDef(row.flatness_lam_grade) && getPrettyBkgColor(row.flatness_lam_grade),
      }),
    },
    {
      Header:'Flatness grade',
      accessor: 'flatness_grade',
      Filter: SelectColumnFilter,
      getCellStyle: (value) => ({
        backgroundColor: isDef(value) && getPrettyBkgColor(value),
        textAlign: 'center'
      }),
    },
    {
      Header:'Width edge',
      accessor: '',
      Cell: (props) => {
        let original_row = props.cell.row.original;
        const listItems = [];
        for (let i = 0; i <= 2; i++) {
          listItems.push(<ListGroupItem key={`width_edge${i}`} style={{backgroundColor : isDef(original_row[`width_edge${i}_grade`]) && getPrettyBkgColor(original_row[`width_edge${i}_grade`])}} >{original_row[`width_edge${i}`]}</ListGroupItem>);
        }
        return(
          <Fragment>
            <ListGroup>              
              {listItems}
            </ListGroup>
          </Fragment>
        );
      }
    },
    {
      Header:'Width corner',
      accessor: '',
      Cell: (props) => {
        let original_row = props.cell.row.original;
        const listItems = [];
        for (let i = 0; i <= 2; i++) {
          listItems.push(<ListGroupItem key={`width_corner${i}`} style={{backgroundColor : isDef(original_row[`width_corner${i}_grade`]) && getPrettyBkgColor(original_row[`width_corner${i}_grade`])}} >{original_row[`width_corner${i}`]}</ListGroupItem>);
        }
        return(
          <Fragment>
            <ListGroup>              
              {listItems}
            </ListGroup>
          </Fragment>
        );
      }
    },
    {
      Header:'Radius',
      accessor: '',
      Cell: (props) => {
        let original_row = props.cell.row.original;
        const listItems = [];
        for (let i = 0; i <= 5; i++) {
          listItems.push(<ListGroupItem key={`radius${i}`} style={{backgroundColor : isDef(original_row[`radius${i}_grade`]) && getPrettyBkgColor(original_row[`radius${i}_grade`])}}  >{original_row[`radius${i}`]}</ListGroupItem>);
        }
        return(
          <Fragment>
            <ListGroup>              
              {listItems}
            </ListGroup>
          </Fragment>
        );
      }
    },
    {
      Header:'Distance from hole to slot',
      accessor: 'dist_hole_slot',
      getCellStyle: (value, row) => ({
        backgroundColor: isDef(row.dist_hole_slot_grade) && getPrettyBkgColor(row.dist_hole_slot_grade),
      }),
    },
    {
      Header:'Diameter hole passed',
      accessor: 'diameter_hole_passed',
      Filter: SelectColumnFilterBoolean,
      Cell: (props) => {
        return(
          <NiceBoolean value={props.cell.value} />
        );
      }
    },
    {
      Header:'Length of slot',
      accessor: 'length_slot',
      getCellStyle: (value, row) => ({
        backgroundColor: isDef(row.length_slot_grade) && getPrettyBkgColor(row.length_slot_grade),
      }),
    },
    {
      Header:'Width of slot passed',
      accessor: 'width_slot_passed',
      Cell: (props) => {
        return(
          <NiceBoolean value={props.cell.value} />
        );
      }
    },
    {
      Header:'Notch size passed',
      accessor: 'notch_size_passed',
      Cell: (props) => {
        return(
          <NiceBoolean value={props.cell.value} />
        );
      }
    },
    {
      Header:'Tab width',
      accessor: '',
      Cell: (props) => {
        let original_row = props.cell.row.original;
        const listItems = [];
        for (let i = 0; i <= 2; i++) {
          listItems.push(<ListGroupItem key={`tab_width${i}`}>{original_row[`tab_width${i}`]}</ListGroupItem>);
        }
        return(
          <Fragment>
            <ListGroup>              
              {listItems}
            </ListGroup>
          </Fragment>
        );
      }
    },    
    {
      Header:'Tab height',
      accessor: '',
      Cell: (props) => {
        let original_row = props.cell.row.original;
        const listItems = [];
        for (let i = 0; i <= 2; i++) {
          listItems.push(<ListGroupItem key={`tab_height${i}`}>{original_row[`tab_height${i}`]}</ListGroupItem>);
        }
        return(
          <Fragment>
            <ListGroup>              
              {listItems}
            </ListGroup>
          </Fragment>
        );
      }
    },  
    {
      Header:'Kapton align passed',
      accessor: 'kapton_align_passed',
      Cell: (props) => {
        return(
          <NiceBoolean value={props.cell.value} />
        );
      }
    },
    {
      Header:'Test date',
      accessor: 'test_date'
    },
    {
      Header:'Tolerance grade',
      accessor: 'tolerance_grade',
      Filter: SelectColumnFilter,
      getCellStyle: (value, row) => ({
        backgroundColor: isDef(value) && getPrettyBkgColor(value),
      }),
    },
    {
      Header:'Comment',
      accessor: 'comment'
    },
    {
      Header:'Shipment date',
      accessor: 'shipment_date',
      Cell: (props) => {
        const date = props.cell.value!=null ? new Date(Date.parse(props.cell.value)) : '';
        return(
          <Fragment>
            {date!=='' && date.toLocaleString("en-US")}
          </Fragment>
        );
      }  
    },
    {
      Header:'Pre QC by',
      accessor: '',
      Cell: (props) => {
        const preqc_date = props.row.original.preqc_date;
        const preqc_user = props.row.original.preqc_user;
        const date = preqc_date!==null ? new Date(Date.parse(preqc_date)) : null;
        return(
          <Fragment>
            {preqc_user}
            <br />
            {date!==null && date.toLocaleString("en-US")}
          </Fragment>
        );
      }      
    },
    {
      Header:'Lamination by',
      accessor: '',
      Cell: (props) => {
        const lam_date = props.row.original.lam_date;
        const lam_user = props.row.original.lam_user;
        const date = lam_date!==null ? new Date(Date.parse(lam_date)) : null;
        return(
          <Fragment>
            {lam_user}
            <br />
            {date!==null && date.toLocaleString("en-US")}
          </Fragment>
        );
      }  
    },
    {
      Header:'Material',
      accessor: 'material_description',
      Filter: SelectColumnFilter,
      getCellStyle: () => ({
        minWidth: '250px',
      }),
    },
    {
      Header:'Kapton',
      accessor: 'kapton_description',
      Filter: SelectColumnFilter,
      getCellStyle: () => ({
        minWidth: '250px',
      }),
    },
    {
      Header:'Tape',
      accessor: 'tape_description',
      Filter: SelectColumnFilter,
      getCellStyle: () => ({
        minWidth: '250px',
      }),
    },
    {
      Header:'Glue',
      accessor: 'epoxy_expiry_date',
      Filter: SelectColumnFilter,
      getCellStyle: () => ({
        minWidth: '250px',
      }),
    },
    {
      Header:'Files',
      accessor: 'backup_files',
      getCellStyle: () => ({
        minWidth: '400px',
      }),
      Cell: (props) => {
        const files = props.cell.value == null ? [] : props.cell.value;
        return(
          <Fragment>
            <ul>
              {files.map((item,i) => (
                <li key={`link${i}`}>
                  <a href={`${API_BASE}/hgcal-img/backup/${item}`} download>
                    {item}
                  </a>
                </li>
              ))}
            </ul>
          </Fragment>
        );
      }  
    }
  ]
  
  async componentDidMount(){
    // this.state.data = await getAllBP();
    let mydata = await getAllBP();
    let modals = [];
    mydata.forEach(item => {
      modals[item['id']] = false;  
    })
    this.setState({data:mydata, baseplates:mydata, editmodals:modals});
  }

  showUnexportedData(){ 
    this.state.loading = true;
    this.state.showUnexported = !this.state.showUnexported;

    if(this.state.showUnexported) {
      let new_data = this.state.data.filter((item) => !item.is_db_central);
      this.state.data = new_data;
    }else{
      this.state.data = this.state.baseplates;
    }
    // This is necessary to force React-table to re-render :(
    this.columns = [...this.columns];
    this.state.loading = false;
    this.setState(this.state);
  }

  // ✅ Function to update table data
  updateCellData = (rowIndex, columnId, value) => {
    this.setState((prevState) => {
      const newData = prevState.data.map((row, index) =>
        index === rowIndex ? { ...row, [columnId]: value } : row
      );
      return { data: newData };
    });
  };

  render(){
    const { user } = this.context;  
    return (
      <Fragment>
        <ProtectedComponent>
          {
            user?.role==='public' && 
            <h4>This page is not public. Please contact the admin to approve access.</h4>
          }
          {
            user?.role!=='public' && 
            <Fragment>
              <Row>
                <Col>
                  <h1>Baseplates</h1>
                </Col>
                <Col style={{textAlign: 'right'}}>
                  <Row>
                    <Link to="/baseplate-lamination-list">
                      Lamination view
                    </Link>
                  </Row>
                  <Row>
                    <Link to="/baseplate-tape-list">
                      Tape view
                    </Link>
                  </Row>
                </Col>
              </Row>
              <br />
              <Row>
                <Col sm={2}>
                  <Button 
                    variant='dark'
                    onClick={() => {
                      this.setState({showAdd:true});          
                    }}
                  >
                    Add baseplate <span>&#43;</span>
                  </Button>
                </Col>
                {this.state.showAdd && 
                  <BaseplateAdd 
                    isOpen={this.state.showAdd} 
                    user={user} 
                    callback={(e) => {
                      this.setState({showAdd:e});
                    }}
                    callbackbps={(e) => {
                      let new_data = this.state.data;
                      new_data.push(e);
                      this.setState({ data: [...new_data] });
                    }}
                  />
                  
                }

                <Accordion style={{width:'800px'}}>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Export from txt list</Accordion.Header>
                    <Accordion.Body style={{height:'300px'}}>                      
                      <Row>
                        <Form.Label column sm={3}>
                          Shipment date
                        </Form.Label>
                        <Col>
                        <DatePicker 
                          className='form-control form-control-solid w-250px'
                          selected={this.state.exportForm.shipment_date} 
                          onChange={(date) => {
                            let {exportForm} = this.state;
                            exportForm['shipment_date'] = date
                            this.setState({exportForm});  
                          }} 
                        />
                        </Col>
                      </Row>
                      <br />
                      <Form.Control 
                        type="text" 
                        placeholder="Comment (destination)" 
                        onChange={(e) => {
                          let {exportForm} = this.state;
                          exportForm['comment']= e.target.value;
                          this.setState({exportForm})
                        }}
                      />
                      <br />
                      <Form.Control 
                        type="file" 
                        accept=".txt" 
                        onChange={(e)=>{
                          const file = e.target.files[0];
                          if (file && file.type === 'text/plain') {
                            const reader = new FileReader();
                            reader.onload = (e) => {
                              let txtresult = e.target.result;
                              let {exportForm} = this.state;
                              exportForm['barcode_list']= txtresult;
                              this.setState({barcode_txt:txtresult, exportForm})
                            };
                            reader.onerror = () => {
                              console.log('Failed to read file')
                              // setError('Failed to read file');
                            };
                            reader.readAsText(file);
                          } else {
                            console.log('Failed to read file')
                            // setError('Please upload a valid .txt file');
                            // setFileContent('');
                          }
                        }} 
                      />
                      <br />
                      <Row>
                        <Col sm={4}>
                          <Button
                            variant='secondary'
                            disabled={this.state.exportForm.barcode_list===null || this.state.exportForm.barcode_list===''}
                            onClick={async()=>{
                              let {exportForm} = this.state;
                              await createXMLnewpartsfilter(exportForm);
                            }}
                          >
                            Export new parts XML {Icons.notebook}
                          </Button>
                        </Col>
                        <Col sm={4}>
                          <Button
                            variant='success'
                            disabled={this.state.exportForm.barcode_list===null || this.state.exportForm.barcode_list===''}
                            onClick={async()=>{
                              let {exportForm} = this.state;
                              await createXMLqcgradefilter(exportForm);
                            }}
                          >
                            Export QC grade XML {Icons.notebook}
                          </Button>
                        </Col>
                        <Col sm={4}>
                          <Button
                            variant='warning'
                            disabled={this.state.exportForm.barcode_list===null || this.state.exportForm.barcode_list==='' || this.state.exportForm.comment===null || this.state.exportForm.comment===''}
                            onClick={async()=>{
                              let {exportForm} = this.state;
                              let updateddata = await updateBPshipment(exportForm);
                              if(updateddata){
                                window.alert(`Updated ${updateddata.length} baseplates`);
                                let {data} = this.state;
                                updateddata.map(item => {
                                  console.log(item)
                                  const index = data.findIndex(bp => bp.id === item.id);
                                  data[index].comment = item.comment;
                                  data[index].shipment_date = item.shipment_date;     
                                  this.setState({ data: [...data] });
                        
                                })

                              }else{
                                window.alert('No baseplates updated.')
                              }
                            }}
                          >
                            Update shipment info {Icons.save}
                          </Button>
                        </Col>
                      </Row>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Row>
              <br />
              <TableWithPagination 
                columns={this.columns}
                data={this.state.data}
                defaultPageSize={50}
                isSelect={true}
                callbackselected={(incoming_data)=>{
                  this.setState({selected: incoming_data});
                }}
              />
            </Fragment>
          }
        </ProtectedComponent>
      </Fragment>
    );
  }
}

export default BaseplateList;