import api, {API_BASE} from './apiService'

export async function getAllTape() {
  const { data } = await api.get(`${API_BASE}/list-tape`, { params :{} });
  return data;
}

export async function addTape(formAdd) {
  const { data } = await api.post(`${API_BASE}/add-tape`, {formAdd});
  return data;
}

export async function deleteTape(id) {
  const {data} = await api.delete(`${API_BASE}/delete-tape/${id}`, {params:{id}})
  return data;
}

export async function updateTape(id, variable, value) {
  const {data} = await api.put(`${API_BASE}/update-tape`, {id, variable, value})
  return data;
}

export async function getSummaryTape() {
  const { data } = await api.get(`${API_BASE}/list-short-tape`, { params :{} });
  return data;
}