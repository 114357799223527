export default {
  remove: '🗑️',
  edit: '✍️',
  save: '💾',
  ok: '✅',
  wrong: '❌',
  empty: '⬜',
  construction: '🚧',
  folder: '📁',
  files: '🗃️',
  plus: '➕',
  notebook:'🗒️',
  info: 'ℹ️',
  up: '⬆️',
  down:'⬇️',
}