import axios from 'axios';
import { notify } from './notificationService';
export const API_BASE = process.env.REACT_APP_API_BASE || 'https://hgcal.etp.kit.edu/api';
export const MEDIA_BASE = process.env.REACT_APP_API_BASE || 'https://hgcal.etp.kit.edu/files';


let params = {baseURL:`https://hgcal.etp.kit.edu/api`, headers: { "Cache-Control": "no-cache" }};
if (process.env.NODE_ENV !== 'development') { 
  params = { 
    baseURL:`https://hgcal.etp.kit.edu/api` ,
    headers: { "Cache-Control": "no-cache" } 
  };
  }

const api = axios.create(params);
const handleSuccess = null;
const handleError = (error) => { 
  let errorMessage = ''; 
  const expectedError = error.response && error.response.status >= 400 && error.response.status < 500; 
  if (!expectedError) { 
    console.log('Logging the error', error); 
    errorMessage = 'An unexpected error accurred. Could not get data from the server'; 
    notify(errorMessage, { type: 'error' }); 
  } 
  return Promise.reject(errorMessage);
};

api.interceptors.response.use(handleSuccess, (error) => handleError(error));

export default api;