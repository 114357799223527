import { notify } from './notificationService';

const lightred = '#ff000094';
const darkgreen = '#198754b8';

export const stringtypes =[{name:"FLW", label:"CuW LD Full"},
  {name:"TLW", label:"CuW LD Top"},
  {name:"BLW", label:"CuW LD Bottom"},
  {name:"LLW", label:"CuW LD Left"},
  {name:"RLW", label:"CuW LD Right"},
  {name:"5LW", label:"CuW LD Five"},
  {name:"FHW", label:"CuW HD Full"},
  {name:"BHW", label:"CuW HD Bottom"},
  {name:"LHW", label:"CuW HD Left"},
  {name:"RHW", label:"CuW HD Right"},
  {name:"FLT", label:"Ti LD Full"},
  {name:"TLT", label:"Ti LD Top"},
  {name:"BLT", label:"Ti LD Bottom"},
  {name:"LLT", label:"Ti LD Left"},
  {name:"RLT", label:"Ti LD Right"},
  {name:"5LT", label:"Ti LD Five"},
  {name:"FHT", label:"Ti HD Full"},
  {name:"THT", label:"Ti HD Top"}]

export function isDef(obj) {
	return typeof obj !== 'undefined';
}

export function validResponse(data) {
	return data && typeof data == 'object';
}

export function notifyResult({ data, success, error }, type = 'object') {
  let isValid = data && data !== '';
  if (type === 'object') {
    isValid = validResponse(data);
  }
  isValid ? notify(success, { type: 'success' }) : notify(error, { type: 'error' });
  return isValid;
}



export function notifyResultWithTime({ data, success, error }, type = 'object') {
  let isValid = data && data !== '';
  if (type === 'object') {
    isValid = validResponse(data);
  }
  isValid
    ? notify(success, { type: 'success', autoClose: 2000 })
    : notify(error, { type: 'error', autoClose: false });
  return isValid;
}

export function isValidAlphanumeric(input) {
  const alphanumericRegex = /^[a-zA-Z0-9-]+$/;
  return alphanumericRegex.test(input);
}

export function isValidDecimal(input) {
  if (input!==''){
  const decimalRegex = /^[+-]?(\d+(\.\d*)?|\.\d+)$/;
  return decimalRegex.test(input);
  }else return true;
}

export function isPositiveInteger(value) {
  if (value!==''){
    const number = Number(value);
    return Number.isInteger(number) && number > 0;
  }else return true;
}

// Function to get the current date in ddmmyy format
export function getCurrentDateDDMMYY() {
  const date = new Date();

  // Extract day, month, and year
  const day = String(date.getDate()).padStart(2, '0'); // Ensure two digits
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const year = String(date.getFullYear()).slice(-2); // Get last two digits of the year

  return `${day}${month}${year}`;
}

export function getFlatnessGrade(height, type){
  if(type==='CuW'){
    const maxmin = Math.max(...height)-Math.min(...height)
    if(maxmin<0.1){
      return 'green';
    }else if(maxmin>=0.1 && maxmin<=0.2){
      return 'orange';
    }else if(maxmin>0.2){
      return 'red';
    }

  }
  if(type==='Ti'){
    const maxmin = Math.max(...height)-Math.min(...height)
    if(maxmin<0.2){
      return 'green';
    }else if(maxmin>=0.2 && maxmin<=0.4){
      return 'orange';
    }else if(maxmin>0.4){
      return 'red';
    }
  }
  return null;
}

export function getHeight(form, type){
  let height = [];
  for (let i = 0; i <= 9; i++) {
    let field = `height_${type}${i}`;
    if(form[field]!==null && form[field]!=='') height.push(parseFloat(form[field]))
  }
  return height;
}

export function typeBaseplate(barcode) {
  if (barcode===null || barcode==='') return '';
  let bptype = '';
  stringtypes.map(item=>{
    if(item["name"]===barcode.substring(5, 8)) bptype = item['label']
  })
	return bptype;
}

export function calcDistHoleSlot(value) {

  if(value==='' || value===null) return '';

  let nominal=74.95;
  // Green: within +/- 0.1 of nominal
  // Orange: within +/- 0.2 of nominal
  // Red: beyond +/-0.2 of nominal

  if(value >= nominal-0.1 && value<= nominal+0.1) return 'lightgreen';
  if(value >= nominal-0.2 && value<= nominal+0.2) return 'orange';
  if(value < nominal-0.2 || value> nominal+0.2) return lightred;
}

export function calcLengthSlot(value) {
  if(value==='' || value===null) return '';

  let nominal=3.55;
  // Length of slot: nominal 3.55

  // Green: within +/- 0.1 of nominal
  // Orange: within +/0.2 of nominal
  // Red: beyond +/-0.2 of nominal

  if(value >= nominal-0.1 && value<= nominal+0.1) return 'lightgreen';
  if(value >= nominal-0.2 && value<= nominal+0.2) return 'orange';
  if(value < nominal-0.2 || value> nominal+0.2) return lightred;
  return '';

}

export function calcGrade(value) {

  if(value==='green') return 'lightgreen';
  if(value==='orange') return 'orange';
  if(value==='red') return lightred;
  return '';
}


export function calcThickness(value, row) {
  if(value==='' || value===null) return '';
  console.log(row)
  let type = typeBaseplate(row.bar_code)
  // CuW - common
  // Thickness: nominal 1.400
  // Green within +/- 0.1 from nominal
  // Orange within +/- 0.2 from nominal
  // Red: beyond +/- 0.2 from nominal
  if(type==='CuW'){
    let nominal = 1.400;
    if(value >= nominal-0.1 && value<= nominal+0.1) return 'lightgreen';
    if(value >= nominal-0.2 && value<= nominal+0.2) return 'orange';
    if(value < nominal-0.2 || value> nominal+0.2) return lightred;
  }

  // Ti - Common
  // Thickness: nominal 1.100
  // Green within +/- 0.1 from nominal
  // Orange within +/- 0.2 from nominal
  // Red: beyond +/- 0.2 from nominal
  if(type==='Ti'){
    let nominal = 1.100;
    if(value >= nominal-0.1 && value<= nominal+0.1) return 'lightgreen';
    if(value >= nominal-0.2 && value<= nominal+0.2) return 'orange';
    if(value < nominal-0.2 || value> nominal+0.2) return lightred;
  }
}

export function calcFlatness(value, row) {
  if(value==='' || value===null) return '';

  let type = typeBaseplate(row.bar_code)
  // CuW - common
  // Green (MAX-MIN) < 0.1
  // Orange (MAX-MIN) < 0.2
  // RED (MAX-MIN) > 0.2
  if(type==='CuW'){
    if(value<=0.1) return 'lightgreen';
    if(value<=0.2) return 'orange';
    if(value>0.2) return lightred;
  }

  // Ti - Common
  // Green (MAX-MIN) < 0.2
  // Orange (MAX-MIN) < 0.4
  // RED (MAX-MIN) > 0.4
  if(type==='Ti'){
    if(value<=0.2) return 'lightgreen';
    if(value<=0.4) return 'orange';
    if(value>0.4) return lightred;
  }
}


export function calcWidthEdge(value) {
  if(value==='' || value===null) return '';

  let nominal=166.94;
  // Width edge: nominal 166.94

  // Deep green, within +0/-0.1 from nominal
  // Light green, within +0.1/-0 from nominal
  // Orange within +0.2/-0.12 of nominal
  // Red: beyond +0.2/-0.12 of nominal

  if(value >= nominal-0.1 && value<= nominal+0.0) return darkgreen;
  if(value >= nominal-0.0 && value<= nominal+0.1) return 'lightgreen';
  if(value >= nominal-0.12 && value<= nominal+0.2) return 'orange';
  if(value < nominal-0.12 || value> nominal+0.2) return lightred;
  return '';

}

export function calcRadius(value) {
  if(value==='' || value===null) return '';

  let nominal=81.95;
  // Radius: nominal 81.95
  // Green: within +/- 0.05 of nominal
  // Orange: within +0.1/-0.06 of nominal
  // Red: beyond +0.1/-0.06 of nominal

  if(value >= nominal-0.05 && value<= nominal+0.05) return 'lightgreen';
  if(value >= nominal-0.06 && value<= nominal+0.1) return 'orange';
  if(value < nominal-0.06 || value> nominal+0.1) return lightred;
}


export function getPrettyBkgColor(value) {
  if(value==='' || value===null) return '';
  if(value==='green') return 'lightgreen';
  if(value==='red') return lightred;
  if(value==='orange') return 'orange';
}