import React, { Fragment } from "react";
import { Table as NiceTable, Pagination, InputGroup, FormControl, Row, Col, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Select from 'react-select';
import { isDef } from "../../services/commonService";
import {useTable,useSortBy, usePagination, useGlobalFilter, useFilters, useRowSelect} from "react-table";
import {matchSorter} from 'match-sorter';

// Define a default UI for filtering
function DefaultColumnFilter({
column: { filterValue, preFilteredRows, setFilter }
}) {
const count = preFilteredRows.length;
const backgroundColor = filterValue ? 'yellow' : '';
return (
  <input
  value={filterValue || ""}
  className="form-control"
  onChange={e => {
    setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
  }}
  placeholder={`Search ${count} records...`}
  style={{fontSize:'smaller', maxHeight: 'min-content', backgroundColor}}
  />
);
}

export function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [row => row.values[id]] });
}

// Let the table remove the filter if the string is emptyfuzzyTextFilterFn.autoRemove = val => !val;
export function SliderColumnFilter({ 
  column: { filterValue, setFilter, preFilteredRows, id },
}) { 
  // Calculate the min and max 
  // using the preFilteredRows 
  const [min, max, mid] = React.useMemo(() => { 
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0; 
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0; 
    let mid = 0; 
    preFilteredRows.forEach(row => { 
      if(mid === 0 && row.values[id] > min) mid = row.values[id]; 
      min = Math.min(row.values[id], min) 
      max = Math.max(row.values[id], max) 
    }) 
    if(mid === 0) mid = max; 
    return [min, max, mid] 
  }, [id, preFilteredRows]) 
  const backgroundColor = filterValue ? 'yellow':'';
  return ( 
    <div style={{backgroundColor, fontSize:'smaller'}}>
      <input
        type="range"
        min={min}
        max={max}
        mid={mid}
        value={filterValue || min}
        onChange={e => {
          setFilter(parseInt(e.target.value, 10));
        }}
      /><br />
      <button onClick={() => setFilter(undefined)} className="btn btn-secondary btn-sm">Off</button> 
    </div>
  )
}

export function filterGreaterThan(rows, id, filterValue) {
  return rows.filter(row => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  })
}
filterGreaterThan.autoRemove = val => typeof val !== 'number'


export function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const optionsArray = [];
    preFilteredRows.forEach(row => {
      optionsArray.push(row.values[id])
    })
    optionsArray.sort();
    const options = new Set(optionsArray);
    return [...options.values()]
  }, [id, preFilteredRows])
  const backgroundColor = filterValue ? 'yellow':'';

  // Render a multi-select box
  return (
    <select
      value={isDef(filterValue) ? filterValue : "all"}
      onChange={e => {
        setFilter(e.target.value || undefined)
      }}
      style={{backgroundColor}}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
        </option>
      ))}
    </select>
  )
}

function TableWithPagination({ columns, data, loading, pageCount: controlledPageCount,
  defaultPageSize, defaultSorted, defaultFiltered, configName, hiddenColumns, showPagination = true, customStyle, copyList, train_cleaned, callback,callbackdata }) {  
    const defaultColumn = React.useMemo(
      () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter
      }),
      []
    );
    const filterTypes = React.useMemo(
      () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
        const rowValue = row.values[id];
        return rowValue !== undefined ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase()) : true;
        });
      }
      }),
      []
    );
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      setAllFilters,
      page,
      // Instead of using 'rows', we'll use page,
      // which has only the rows for the active page
      // rows,
      // Pagination Props
      // The rest of these things are super handy, too ;)
      canPreviousPage, 
      canNextPage, 
      pageOptions, 
      pageCount, 
      gotoPage, 
      nextPage, 
      previousPage, 
      setPageSize, 
      selectedFlatRows, 
      state: { pageIndex, pageSize, sortBy, initialsortBy, initialfilters, filters, selectedRowIds }, 
      state,
      // Search / Filtering Props
      // preGlobalFilteredRows,
      // setGlobalFilter
    } = useTable(
      { 
        columns, 
        data, 
        initialState: { pageIndex: 0, pageSize: defaultPageSize || 20, initialsortBy:defaultSorted || [] , initialfilters:defaultFiltered|| [] ,sortBy: defaultSorted || [], filters: defaultFiltered || [], hiddenColumns: hiddenColumns || [] }, // Pass our hoisted table state 
        defaultColumn, 
        filterTypes, 
        // autoResetPage: false, //disabled to reset pagination on filter change
        autoResetExpanded: false,
        autoResetGroupBy: false,
        autoResetSelectedRows: false,
        autoResetSortBy: false,
        autoResetFilters: false,
        autoResetRowState: false,
      },
      useFilters,
      useGlobalFilter,
      useSortBy,
      usePagination,
      useRowSelect,
    );

    state.hiddenColumns = hiddenColumns || [];
    return ( 
      <React.Fragment> 
        <div style={{paddingBottom:'2px'}}> 
          <Button size="sm" variant="secondary" disabled={filters.length===0} onClick={() => setAllFilters([])}>Clear all filters</Button> 
        </div>
        <NiceTable striped bordered hover size="sm" {...getTableProps()} style={customStyle} key="nicetable">
          <thead key={`thead`}>
            {headerGroups.map((headerGroup, m) => (
              <Fragment key={`th${m}`}>
                <tr {...headerGroup.getHeaderGroupProps()} key={`tr${m}`} style={{position:'sticky', top:'50px', zIndex:100}} >
                  {headerGroup.headers.map((column,g) => (
                    <th 
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      {...column.style}
                      style={{verticalAlign:'middle', textAlign:'center', minWidth: column.minWidth, width: column.width}}
                      key={g}
                    >
                      {column.render('Header')}
                      <span style={{fontSize: '80%'}}>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? ' 🔽' 
                            : ' 🔼'
                          : ''}
                      </span>
                      <div>{column.canFilter ? column.render('Filter') : null}</div>
                    </th>
                  ))}
                </tr> 
      
              </Fragment>
            ))}

          </thead>
                
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
            <tr {...row.getRowProps()} key={i}>
              {row.cells.map((cell,j) => {
              return (
                <td {...cell.getCellProps({
                  style: {
                    minWidth: cell.column.minWidth,
                    width: cell.column.width,
                    maxWidth: cell.column.maxWidth,
                  },
                  })}  key={j}>{cell.render("Cell")}</td>
              );
              })}
            </tr>
            );
          })
          }
          {}
        </tbody>
      </NiceTable>
      {showPagination && <Row>
        <Col md={2}>
          <Pagination>
            <InputGroup className="mb-3">
              {/* <InputGroup.Prepend> */}
                {/* <Pagination.First onClick={() => gotoPage(0)} disabled={!canPreviousPage} /> */}
                <Pagination.Prev onClick={() => previousPage()} disabled={!canPreviousPage} />
              {/* </InputGroup.Prepend> */}
              <FormControl
                type="number"
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(page);
                }}
                aria-label="Amount (to the nearest dollar)"
                value={pageIndex + 1}
              />
              {/* <InputGroup.Append> */}
                <Pagination.Next onClick={() => nextPage()} disabled={!canNextPage} />
                {/* <Pagination.Last onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} /> */}
              {/* </InputGroup.Append> */}
            </InputGroup>
          </Pagination>
          </Col>
        {/* <Col md={2}>
          <Select
            onChange={(e) => {
              setPageSize(Number(e.value));
            }}
            options={[ 10, 20, 30, 40, 50 ].map((pageSize) => {
              return { value: pageSize, label: `Show ${pageSize}` };
            })}
            value={{ value: pageSize, label: `Show ${pageSize}` }}
          />
        </Col>
        <Col md={2}>
          <label className="m-2">
            Page{' '}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{' '}
          </label>
        </Col> */}
        </Row>}
      </React.Fragment>
    );    
 }


export default TableWithPagination;
