import React, {useContext} from "react";
import { Link} from "react-router-dom";
import { Navbar, Nav, Button, Container } from 'react-bootstrap';
import { AuthContext } from "../Login/AuthContext";

function NavbarComponent(){
  const { user, logout } = useContext(AuthContext);

  return(
    <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary" data-bs-theme="dark">
      <Container>
        <Navbar.Brand href="/">HGCAL DB</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/">HGCAL DB</Nav.Link>
            <Nav.Link href="/glue-list">Glues</Nav.Link>
            <Nav.Link href="/material-list">Material</Nav.Link>
            <Nav.Link href="/baseplate-list">Baseplate</Nav.Link>
          </Nav>
          <Nav>
            <Nav.Link href="/user-list">Users</Nav.Link>
            <Nav.Link>
              {user?.username}
              {user && <Button variant='link' size='sm' onClick={logout}>Logout</Button>}
              {!user && <Link to="/login">Login</Link>}
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavbarComponent;


