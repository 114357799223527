import React, { Fragment } from 'react';

const Home = () => {

  return (
    <Fragment >
      <h1>Welcome to HGCal ETP database</h1>

      <div>Baseplate description can be found <a href='https://gitlab.etp.kit.edu/hgcal-etp-team/baseplate/-/wikis/CMS-Central-DB' target='_blank' rel="noopener noreferrer">here</a></div>
    </Fragment>
  );
}


export default Home;
