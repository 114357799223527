import React, { Component, Fragment } from 'react';
import TableWithPagination from './common/TableWithPagination';
import { Button, Modal, ListGroup, ListGroupItem, Form, Row, Col, ButtonGroup } from 'react-bootstrap'
import { isDef } from '../services/commonService';
import { getAllBP, deleteBP, addBP, createXMLnewparts, createXMLqcgrade } from '../services/baseplateService';
import Icons from './common/Icons';
import BaseplateEdit from './BaseplateEdit';
import ProtectedComponent from './common/ProtectedComponent';
import { AuthContext } from './Login/AuthContext';
import { Link } from 'react-router-dom';
import { NiceBoolean } from './common/Common';
import { json2xml } from "xml-js";

class BaseplateList extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);
    this.state = {
      data : [],
      showAdd: false,
      showEdit: false,
      selected: [],
      formAdd: {
        barcode : null,
        weight_bare: null,
        weight_lam: null,
        thickness: null,
        height_bare: null,
        height_lam: null,
        flatness_grade: null,
        width_edge: null,
        width_corner: null,
        radius: null,
        dist_hole_slot: null,
        diameter_hole_passed: null,
        length_of_slot: null,
        width_lot_passed: null,
        notch_size_passed: null,
        tab_width: null,
        tab_height: null,
        kapton_align_passed: null,
        test_date: null,
        tolerance_grade: null,
        comment: null,
      }
    }
  }

  columns = [
    {
      Header:'',
      accessor:'id',
      Cell: (props) => {
        const id = props.cell.value;
        return(
          <Fragment>
            <Form.Check 
              onChange={(e)=>{
                let isChecked = e.target.checked;
                let { selected } = this.state;
                if(isChecked){
                  selected.push(id);
                  this.setState({selected});
                }else{
                  let newSelected = selected.filter((item) => item !== id);
                  this.setState({selected:newSelected});
                }
              }}
            />
          </Fragment>
        );
      }
    },
    {
      Header:'Barcode',
      minWidth: 270,
      accessor: 'bar_code',
      filter: 'fuzzyText',
      Cell: (props) => {
        let id = props.row.original.id;
        return(
          <Fragment>
            {props.cell.value}
            {props.row.original.edit && (
              <BaseplateEdit 
                isOpen={props.row.original.edit}
                id={id}
                username={this.context.user.username}
              />
            )}
            <ButtonGroup aria-label="Bp actions">
              {/* <Link to={`/baseplate-media/${id}`} disabled>
                <Button
                  variant='light' 
                  size='sm'  
                >
                  {Icons.folder}
                </Button>
              </Link> */}
              <Button 
                variant='light' 
                size='sm'
                onClick={()=>{
                  let mydata = this.state.data;
                  mydata.forEach(item => {
                    if (item['id']===id){
                      item['edit']=true;
                    }
                  })
                  this.setState({data:mydata}); 
                }}
                >
                {Icons.edit}
              </Button>

              <Button 
                variant='light' 
                size='sm'
                onClick= {async () => {
                  let deleted_id = await deleteBP(id); 
                  let new_data =  this.state.data.filter(item => item.id!==deleted_id.id)
                  this.setState({data:new_data}); 
                }}
              >
                {Icons.remove}
              </Button>
            </ButtonGroup>
          </Fragment>
        );
      }
    },
    {
      Header:'Weight bare',
      accessor: 'weight_bare'
    },
    {
      Header:'Weight lamination',
      accessor: 'weight_lam'
    },
    {
      Header:'Thickness',
      accessor: 'thickness'
    },
    {
      Header:'Height bare',
      accessor: 'height_bare',
      Cell: (props) => {
        let height_bare = props.cell.value;
        return(
          <Fragment>
            <ListGroup>
              {isDef(height_bare) && height_bare!=null &&
              Object.keys(height_bare).map(([key,item])=>(
                <ListGroupItem key={key}>{height_bare[key]}</ListGroupItem>
              ))
              }
            </ListGroup>
          </Fragment>
        );
      }
    },   
    {
      Header:'Flatness bare',
      accessor: '',
      Cell: (props) => {
        const height_bare = props.row.original.height_bare;
        const maxValue = height_bare!==null && Math.max(...Object.values(height_bare));
        const minValue = height_bare!==null && Math.min(...Object.values(height_bare));
        const flatness_bare = height_bare!==null && (maxValue-minValue).toFixed(3);
        return(
          <Fragment>
            {flatness_bare}
          </Fragment>
        );
      }
    },
    {
      Header:'Height lam',
      accessor: 'height_lam',
      Cell: (props) => {
        let widthedge = props.cell.value;
        return(
          <Fragment>
            <ListGroup>
              {isDef(widthedge) && widthedge!=null &&
              Object.keys(widthedge).map(([key,item])=>(
                <ListGroupItem key={key}>{widthedge[key]}</ListGroupItem>
              ))
              }
            </ListGroup>
          </Fragment>
        );
      }
    },   
    {
      Header:'Flatness lam',
      accessor: '',
      Cell: (props) => {
        const height_lam = props.row.original.height_lam;
        const maxValue = height_lam!==null && Math.max(...Object.values(height_lam));
        const minValue = height_lam!==null && Math.min(...Object.values(height_lam));
        const flatness_lam = height_lam!==null && (maxValue-minValue).toFixed(3);
        return(
          <Fragment>
            {flatness_lam}
          </Fragment>
        );
      }
    },
    {
      Header:'Flatness grade',
      accessor: 'flatness_grade',
      Cell: (props)=>{
        let mycolor=props.cell.value
        return(
          <span style={{backgroundColor:mycolor==='green' ? 'lightgreen' : mycolor==='red' ? '#ff0000b3' : mycolor }}>{mycolor}</span>
        );
      }
    },
    {
      Header:'Width edge',
      accessor: 'width_edge',
      Cell: (props) => {
        let widthedge = props.cell.value;
        return(
          <Fragment>
            <ListGroup>
              {isDef(widthedge) && widthedge!=null &&
              Object.keys(widthedge).map(([key,item])=>(
                <ListGroupItem key={key}>{widthedge[key]}</ListGroupItem>
              ))
              }
            </ListGroup>
          </Fragment>
        );
      }
    },
    {
      Header:'Width corner',
      accessor: 'width_corner',
      Cell: (props) => {
        let widthedge = props.cell.value;
        return(
          <Fragment>
            <ListGroup>
              {isDef(widthedge) && widthedge!=null &&
              Object.keys(widthedge).map(([key,item])=>(
                <ListGroupItem key={key}>{widthedge[key]}</ListGroupItem>
              ))
              }
            </ListGroup>
          </Fragment>
        );
      }
    },
    {
      Header:'Radius',
      accessor: 'radius',
      Cell: (props) => {
        let widthedge = props.cell.value;
        return(
          <Fragment>
            <ListGroup>
              {isDef(widthedge) && widthedge!=null &&
              Object.keys(widthedge).map(([key,item])=>(
                <ListGroupItem key={key}>{widthedge[key]}</ListGroupItem>
              ))
              }
            </ListGroup>
          </Fragment>
        );
      }
    },
    {
      Header:'Distance from hole to slot',
      accessor: 'dist_hole_slot'
    },
    {
      Header:'Diameter hole passed',
      accessor: 'diameter_hole_passed',
      Cell: (props) => {
        return(
          <NiceBoolean value={props.cell.value} />
        );
      }
    },
    {
      Header:'Length of slot',
      accessor: 'length_slot'
    },
    {
      Header:'Width of slot passed',
      accessor: 'width_slot_passed',
      Cell: (props) => {
        return(
          <NiceBoolean value={props.cell.value} />
        );
      }
    },
    {
      Header:'Notch size passed',
      accessor: 'notch_size_passed',
      Cell: (props) => {
        return(
          <NiceBoolean value={props.cell.value} />
        );
      }
    },
    {
      Header:'Tab width',
      accessor: 'tab_width',
      Cell: (props) => {
        let widthedge = props.cell.value;
        return(
          <Fragment>
            <ListGroup>
              {isDef(widthedge) && widthedge!=null &&
              Object.keys(widthedge).map(([key,item])=>(
                <ListGroupItem key={key}>{widthedge[key]}</ListGroupItem>
              ))
              }
            </ListGroup>
          </Fragment>
        );
      }
    },    
    {
      Header:'Tab height',
      accessor: 'tab_height',
      Cell: (props) => {
        let widthedge = props.cell.value;
        return(
          <Fragment>
            <ListGroup>
              {isDef(widthedge) && widthedge!=null &&
              Object.keys(widthedge).map(([key,item])=>(
                <ListGroupItem key={key}>{widthedge[key]}</ListGroupItem>
              ))
              }
            </ListGroup>
          </Fragment>
        );
      }
    },  
    {
      Header:'Kapton align passed',
      accessor: 'kapton_align_passed',
      Cell: (props) => {
        return(
          <NiceBoolean value={props.cell.value} />
        );
      }
    },
    {
      Header:'Test date',
      accessor: 'test_date'
    },
    {
      Header:'Tolerance grade',
      accessor: 'tolerance_grade',
      Cell: (props)=>{
        let mycolor=props.cell.value
        return(
          <span style={{backgroundColor:mycolor==='green' ? 'lightgreen' : mycolor==='red' ? '#ff0000b3' : mycolor }}>{mycolor}</span>
        );
      }
    },
    {
      Header:'Comment',
      accessor: 'comment'
    },
    {
      Header:'Shipment date',
      accessor: 'shipment_date',
      Cell: (props) => {
        const date = props.cell.value!=null ? new Date(Date.parse(props.cell.value)) : '';
        return(
          <Fragment>
            {date!=='' && date.toLocaleString("en-US")}
          </Fragment>
        );
      }  
    },
    {
      Header:'Pre QC by',
      accessor: '',
      Cell: (props) => {
        const preqc_date = props.row.original.preqc_date;
        const preqc_user = props.row.original.preqc_user;
        const date = preqc_date!==null ? new Date(Date.parse(preqc_date)) : null;
        return(
          <Fragment>
            {preqc_user}
            <br />
            {date!==null && date.toLocaleString("en-US")}
          </Fragment>
        );
      }      
    },
    {
      Header:'Lamination by',
      accessor: '',
      Cell: (props) => {
        const lam_date = props.row.original.lam_date;
        const lam_user = props.row.original.lam_user;
        const date = lam_date!==null ? new Date(Date.parse(lam_date)) : null;
        return(
          <Fragment>
            {lam_user}
            <br />
            {date!==null && date.toLocaleString("en-US")}
          </Fragment>
        );
      }  
    },
  ]
  
  async componentDidMount(){
    // this.state.data = await getAllBP();
    let mydata = await getAllBP();
    mydata.forEach(item => {
      item['edit'] = false;      
    })
    this.setState({data:mydata});
  }

  changeFormAdd(e, field){
    let {formAdd} = this.state;
    formAdd[field] =  e.target.value;
    this.setState({formAdd});
  }

  arrayFormAdd(field, size, label){
    let {formAdd} = this.state;
    let myarray = formAdd[field]==null ? new Array(size) : formAdd[field];  
    if(formAdd[field]==null){
      for (let i = 0; i < size; i++) {
        myarray[i] = "null";
      }
    }
    return(
      <Fragment key={field}>
        <Row>
          <Form.Label column sm={1}>
            {label}
          </Form.Label>
          {[...Array(size)].map((x, i) =>
            <Col key={i}>
              <Form.Control 
                style={{minWidth:'100px'}} 
                key={i} 
                onChange={(e)=>{
                  myarray[i] = e.target.value;
                  formAdd[field] = myarray;
                  this.setState({formAdd});
                }}
                placeholder= {`${field.toUpperCase()}${i}`}
              />
            </Col>
          )}
        </Row>
      </Fragment>
    )
  }

  boolFormAdd(field, label){
    return(
      <Fragment key={`${field}boolean`}>
        <Row>
          <Form.Label column sm={1}>
            {label}
          </Form.Label>
          <Col sm={2}>
            <Form.Select onChange={(e)=>{this.changeFormAdd(e,field)}}>
              <option value="null">{Icons.empty}</option>
              <option value="true">{Icons.ok}</option>
              < option value="false">{Icons.wrong}</option>
            </Form.Select>
          </Col>
        </Row>  
      </Fragment>
    )
  }

  fieldFormAdd(field, label, placeholder){
    return(
      <Fragment key={`${field}field`}>
        <Row>
          <Form.Label column sm={1}>
            {label}
          </Form.Label>
          <Col sm={2}>
            <Form.Control 
              type="text" 
              placeholder={placeholder} 
              onChange={(e)=>{this.changeFormAdd(e,field)}}
              />
          </Col>
        </Row>
      </Fragment>
    )
  }


  render(){
    const { user } = this.context;

    return (
      <Fragment>
        <ProtectedComponent>
          {
            user?.role==='public' && 
            <h4>This page is not public. Please contact the admin to approve access.</h4>
          }
          {
            user?.role!=='public' && 
            <Fragment>
              <Row style={{textAlign: 'right'}}>
                <Link to="/baseplate-lamination-list">
                  Lamination view
                </Link>
              </Row>
              <h1>Baseplates</h1>
              <br />
              <ButtonGroup className="me-2">
                <Button 
                variant='dark'
                onClick={() => {
                  this.setState({showAdd:true});          
                }}
                >
                  Add baseplate <span>&#43;</span>
                </Button>
                <Button 
                  variant='secondary'
                  disabled={this.state.selected.length==0}
                  onClick={async()=>{await createXMLnewparts(this.state.selected)}} 
                >
                  Export new parts XML {Icons.notebook}
                </Button>
                <Button 
                  variant='success'
                  disabled={this.state.selected.length==0}
                  onClick={async()=>{await createXMLqcgrade(this.state.selected)}} 
                >
                  Export QC grade XML {Icons.notebook}
                </Button>
              </ButtonGroup>
              <br /><br />
              <Modal 
                show={this.state.showAdd} 
                onHide={() => {
                  this.setState({showAdd:false});  
                } }
                animation={false}
                size='xl'
                aria-labelledby="contained-modal-title-vcenter"
                scrollable
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title>Add new baseplate</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form style={{ minWidth:'2000px'}}>
                    {this.fieldFormAdd('bar_code','BAR CODE','e.g. 320BAFLWKA00173')}
                    <br />
                    {this.fieldFormAdd('weight_bare', 'WEIGHT BARE','')}
                    <br />
                    {this.fieldFormAdd('weight_lam', 'WEIGHT LAM','')}
                    <br />
                    {this.fieldFormAdd('thickness', 'THICKNESS','')}
                    <br />
                    {this.arrayFormAdd('height_bare',10,'HEIGHT BARE')}
                    <br />
                    {this.arrayFormAdd('height_lam',10,'HEIGHT LAM')}
                    <br />
                    <Row>
                      <Form.Label column sm={1}>
                        FLATNESS GRADE
                      </Form.Label>
                      <Col sm={2}>
                        <Form.Select onChange={(e)=>{this.changeFormAdd(e,'flatness_grade')}}>
                          <option value="null">None</option>
                          <option value="green">green</option>
                          <option value="orange">orange</option>
                          <option value="red">red</option>
                        </Form.Select>
                      </Col>
                    </Row>  
                    <br />
                    {this.arrayFormAdd('width_edge',3,'WIDTH EDGE')}  
                    <br />
                    {this.arrayFormAdd('width_corner',3,'WIDTH CORNER')}    
                    <br />
                    {this.arrayFormAdd('radius',6,'RADIUS')}      
                    <br />
                    {this.fieldFormAdd('dist_hole_slot', 'DISTANCE FROM HOLE TO SLOT','')}
                    <br />
                    {this.boolFormAdd('diameter_hole_passed','DIAMETER HOLE PASSED')}
                    <br />
                    {this.fieldFormAdd('length_slot', 'LENGTH OF SLOT','')}
                    <br />
                    {this.boolFormAdd('width_slot_passed','WIDTH SLOT PASSED')}
                    <br />
                    {this.boolFormAdd('notch_size_passed','NOTCH SIZE PASSED')}
                    <br />
                    {this.arrayFormAdd('tab_width',3,'TAB WIDTH')}  
                    <br />
                    {this.arrayFormAdd('tab_height',3,'TAB HEIGHT')} 
                    <br />
                    {this.boolFormAdd('kapton_align_passed','KAPTON ALIGN PASSED')}
                    <br />
                    <Row>
                      <Form.Label column sm={1}>
                        TOLERANCE GRADE
                      </Form.Label>
                      <Col sm={2}>
                        <Form.Select onChange={(e)=>{this.changeFormAdd(e,'tolerance_grade')}}>
                          <option value="null">None</option>
                          <option value="green">green</option>
                          <option value="orange">orange</option>
                          <option value="red">red</option>
                        </Form.Select>
                      </Col>
                    </Row> 
                    <br />
                    <Row>
                      <Form.Label column sm={1}>
                        COMMENT
                      </Form.Label>
                      <Col sm={3}>
                        <Form.Control 
                          as="textarea" 
                          rows={3}
                          onChange={(e)=>{this.changeFormAdd(e,'comment')}}
                          />
                      </Col>
                    </Row>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={() => {
                  this.setState({showAdd:false});  
                }}>
                    Close
                  </Button>
                  <Button 
                    variant="primary" 
                    onClick={async () => {
                      let new_item = await addBP(this.state.formAdd, user.username);
                      let new_data = this.state.data;
                      new_data.push(new_item);     
                      this.setState({showAdd: false, data: new_data});  
                      window.location.reload();      
                    }}
                  >
                    Add
                  </Button>
                </Modal.Footer>
              </Modal>
     
              <TableWithPagination 
                columns={this.columns}
                data={this.state.data}
                defaultPageSize={50}
              />
            </Fragment>
          }
        </ProtectedComponent>
      </Fragment>
    );
  }
}

export default BaseplateList;