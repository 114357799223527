import React, { Component, Fragment } from 'react';
import { getAllGlues, addGlue, deleteGlue } from '../services/glueService';
import TableWithPagination from './common/TableWithPagination';
import { Button, Modal } from 'react-bootstrap'
import DatePicker from "react-datepicker";
import Icons from './common/Icons';
import ProtectedComponent from './common/ProtectedComponent';
import { AuthContext } from './Login/AuthContext';

import "react-datepicker/dist/react-datepicker.css";
class GlueList extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);
    this.state = {
      data : [],
      show : false,
      formAdd : {
        epoxy_expiry_date: new Date(),
      }
    }
  }
  columns = [
    {
      Header:'id',
      accessor: 'id',
      Cell: (props) => {
        let id = props.cell.value;
        return(
          <Fragment>
            <span>{id} {' '}</span>
            {' '}
            <Button 
              variant="info"
              disabled={this.context.user.role!=='admin'}
              onClick= {async () => {
                let deleted_id = await deleteGlue(id); 
                // deleted_id.id>0 && 
                let new_data =  this.state.data.filter(item => item.id!==deleted_id.id)
                this.setState({data:new_data}); 
              }}
            >
              {Icons.remove}
            </Button>           
          </Fragment>

        );
      }
    },
    {
      Header:'Epoxy expiry date (CEST)',
      accessor: 'epoxy_expiry_date',
      Cell: (props) => {
        let epoxy_expiry_date = new Date(props.cell.value).toLocaleDateString("en-US");
        return(
          <Fragment>
            <span>{epoxy_expiry_date}</span>         
          </Fragment>

        );
      }
    },
  ]

  async componentDidMount(){
    let new_data = await getAllGlues();
    this.setState({data:new_data});
  }
  
  render(){
    const { user } = this.context;

    return (
      <Fragment>
        <ProtectedComponent>
          {
            user?.role==='public' && 
            <h4>This page is not public. Please contact the admin to approve access.</h4>
          }
          {
            user?.role!=='public' && 
            <Fragment>
            <h1>Glue List</h1>
            <Button onClick={() => {
              this.setState({show:true});          
            }}>
              Add glue
            </Button>
            <Modal 
              show={this.state.show} 
              onHide={() => {
                this.setState({show:false});  
              } }
              animation={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>Add new glue</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <h4>
                  Choose an epoxy expire date
                </h4>
                <DatePicker 
                  className='form-control form-control-solid w-250px '
                  selected={this.state.formAdd.epoxy_expiry_date} 
                  onChange={(date) => {
                    let formAdd = this.state.formAdd;
                    formAdd['epoxy_expiry_date'] = date
                    this.setState({formAdd});  
                  }} 
                />
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => {
                this.setState({show:false});  
              }}>
                  Close
                </Button>
                <Button 
                  variant="primary" 
                  onClick={async () => {
                    let new_item = await addGlue(this.state.formAdd);
                    let new_data = this.state.data;
                    new_data.push(new_item);     
                    this.setState({show: false, data: new_data});  
                    window.location.reload();      
                  }}
                >
                  Add
                </Button>
              </Modal.Footer>
            </Modal>
            <br />
            <br />
            <TableWithPagination 
              columns={this.columns}
              data={this.state.data}
              defaultPageSize={10}
            />
          </Fragment>
          }
        </ProtectedComponent>
      </Fragment>
    );
  }
}

export default GlueList;