import {toast} from 'react-toastify';
const defaultOptions = {
  position: 'top-center',
  autoClose: 3000,
  hideProgressBar: true
};

const notify = (content = '', options = {}) => {
  options = Object.assign(defaultOptions, options);
  toast.error(content, options);
}

export { notify }