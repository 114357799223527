import React, { Component, Fragment } from 'react';
import { getAllUsers, addUser, deleteUser, updateUser} from '../services/userService';
import { Button, Modal, Form, Row, Col } from 'react-bootstrap'
import Icons from './common/Icons';
import TableWithPagination from './common/TableWithPagination';
import ProtectedComponent from './common/ProtectedComponent';
import { AuthContext } from './Login/AuthContext';


class UserList extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);
    this.state = {
      data : [],
      form: { username:'', role:'user' },
      new_role: '',
      isAdmin: false
    }
  }

  columns = [
    {
      Header:'username',
      accessor: 'username',
      Cell: (props) => {
        let username = props.cell.value;
        return(
          <Fragment>
            <span>{username} {' '}</span>
            {this.state.isAdmin &&
              <Button 
                variant="light"
                onClick= {async () => {
                  let deleted_user = await deleteUser(username); 
                  let new_data =  this.state.data.filter(item => item.username!==deleted_user.username)
                  // this.state.data = new_data;
                  // this.setState(this.state); 
                  this.setState({ data:new_data }); 
                }}
              >
                {Icons.remove}
              </Button>  
            }         
          </Fragment>

        );
      }
    },
    {
      Header:'role',
      accessor: 'role',
      Cell: (props) => {
        return(
          <Fragment>
            <Form>
              <Row>
                <Col>
                <Form.Select 
                  id="roleselect_user"
                  defaultValue={props.cell.value}
                  disabled={!this.state.isAdmin}
                  onChange={(e) => {
                    this.setState({new_role:e.target.value});  
                  }}
                >
                  <option>admin</option>
                  <option>user</option>
                  <option>public</option>

                </Form.Select>
                </Col>
                <Col>
                {this.state.isAdmin &&
                  <Button 
                    variant="light"
                    size="sm" 
                    onClick={async()=>{
                      let username = props.row.values.username; 
                      let new_role = this.state.new_role;
                      let update_user = await updateUser(username, new_role); 
                      if(update_user!==null){
                        let new_data =  this.state.data;
                        new_data.forEach(item => {
                          if(item.username===username) item.role = new_role;
                        })
                        this.setState({ data:new_data }); 
                      }
                    }}
                  >
                    {Icons.save}
                  </Button>
                }
                </Col>
              </Row>
            </Form>      
          </Fragment>

        );
      }
    },
  ]

  async componentDidMount(){
    const { user } = this.context;
    if(user){
      let data = await getAllUsers();
      let user_role = user.role;
      let isAdmin = user_role==='admin' ? true : false;
      this.setState({data, isAdmin});
    }
  }
  
  render(){
    const { user } = this.context;
    return (
      <Fragment>
        <ProtectedComponent>
          {
            user?.role==='public' && 
            <h4>This page is not public. Please contact the admin to approve access.</h4>
          }
          {
            user?.role!=='public' && 
            <Fragment>
              <h1>HGCal ETP users</h1>
              {this.state.isAdmin && 
              <Fragment>
                <Button onClick={() => {
                  this.setState({show:true});          
                }}>
                  Add user
                </Button>
                <Modal 
                  show={this.state.show} 
                  onHide={() => {
                    this.setState({show:false});  
                  } }
                  animation={false}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Add new user</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <h4>
                      Choose an epoxy expire date
                    </h4>
                    <Form>
                      <Form.Group className="mb-3" controlId="formUsername">
                        <Form.Label>ETP username</Form.Label>
                        <Form.Control 
                          type="text" 
                          placeholder="Enter username" 
                          onChange={(e) => {
                            let new_form = this.state.form;     
                            new_form.username = e.target.value;   
                            this.setState({form:new_form});  
                          }}
                          />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formRole">
                        <Form.Label>Role</Form.Label>
                        <Form.Select 
                          id="roleselect"
                          defaultValue={"user"}
                          onChange={(e) => {
                            let new_form = this.state.form;     
                            new_form.role = e.target.value;               
                            this.setState({form:new_form});  
                          }}
                        >
                          <option>admin</option>
                          <option>user</option>
                          <option>public</option>

                        </Form.Select>
                      </Form.Group>
                    </Form>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                    this.setState({show:false});  
                  }}>
                      Close
                    </Button>
                    <Button 
                      variant="primary" 
                      onClick={async () => {
                        let {username, role} = this.state.form
                        let new_item = await addUser(username,role);
                        let new_data = this.state.data;
                        new_data.push(new_item);     
                        this.setState({show: false, data: new_data});  
                        window.location.reload();      
                      }}
                    >
                      Add
                    </Button>
                  </Modal.Footer>
                </Modal>
                <br />
                <br />
              </Fragment>

              }

              <TableWithPagination 
                columns={this.columns}
                data={this.state.data}
                defaultPageSize={10}
              />
            </Fragment>
          }
        </ProtectedComponent>
      </Fragment>
    );
  }
}

export default UserList;