import React, { Component, Fragment } from 'react';
import ProtectedComponent from './common/ProtectedComponent';

class TapeList extends Component {
  constructor(props){
    super(props);
    this.state = {
      glues : {}
    }
  }
  
  render(){
    return (
      <Fragment>
        <ProtectedComponent>
          <h1>baseplate LIST</h1>
        </ProtectedComponent>
      </Fragment>
    );
  }
}

export default TapeList;