import React, { Component, Fragment } from 'react';
import TableWithPagination from './common/TableWithPagination';
import { Button, ListGroup, ListGroupItem, Form, Row, Col } from 'react-bootstrap'
import { isDef } from '../services/commonService';
import { getAllBP } from '../services/baseplateService';
import Icons from './common/Icons';
import BaseplateEdit from './BaseplateEdit';
import ProtectedComponent from './common/ProtectedComponent';
import { AuthContext } from './Login/AuthContext';
import { Link } from 'react-router-dom';
import { NiceBoolean } from './common/Common';

class BaseplateLam extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);
    this.state = {
      data : [],
      showEdit: false,
    }
  }

  columns = [
    {
      Header:'Barcode',
      minWidth: 260,
      accessor: 'bar_code',
      filter: 'fuzzyText',
      Cell: (props) => {
        let id = props.row.original.id;
        return(
          <Fragment>
            {props.cell.value}
            <Button 
              variant='light' 
              size='sm'
              onClick={()=>{
                let mydata = this.state.data;
                mydata.forEach(item => {
                  if (item['id']===id){
                    item['edit']=true;
                  }
                })
                this.setState({data:mydata}); 
              }}
              >
              {Icons.edit}
              </Button>
              {props.row.original.edit && (
                <BaseplateEdit 
                  isOpen={props.row.original.edit}
                  id={id}
                  username={this.context.user.username}
                />
               )}
          </Fragment>
        );
      }
    },
    {
      Header:'Width edge',
      accessor: 'width_edge',
      Cell: (props) => {
        let widthedge = props.cell.value;
        return(
          <Fragment>
            <ListGroup>
              {isDef(widthedge) && widthedge!=null &&
              Object.keys(widthedge).map(([key,item])=>(
                <ListGroupItem key={key}>{widthedge[key]}</ListGroupItem>
              ))
              }
            </ListGroup>
          </Fragment>
        );
      }
    },
    {
      Header:'Flatness grade',
      accessor: 'flatness_grade',
      Cell: (props)=>{
        let mycolor=props.cell.value
        return(
          <span style={{backgroundColor:mycolor==='green' ? 'lightgreen' : mycolor==='red' ? '#ff0000b3' : mycolor }}>{mycolor}</span>
        );
      }
    },
    {
      Header:'Diameter hole passed',
      accessor: 'diameter_hole_passed',
      Cell: (props) => {
        return(
          <NiceBoolean value={props.cell.value} />
        );
      }
    },
    {
      Header:'Width of slot passed',
      accessor: 'width_slot_passed',
      Cell: (props) => {
        return(
          <NiceBoolean value={props.cell.value} />
        );
      }
    },
    {
      Header:'Notch size passed',
      accessor: 'notch_size_passed',
      Cell: (props) => {
        return(
          <NiceBoolean value={props.cell.value} />
        );
      }
    },
    {
      Header:'Tolerance grade',
      accessor: 'tolerance_grade',
      Cell: (props)=>{
        let mycolor=props.cell.value
        return(
          <span style={{backgroundColor:mycolor==='green' ? 'lightgreen' : mycolor==='red' ? '#ff0000b3' : mycolor }}>{mycolor}</span>
        );
      }
    },
  ]
  
  async componentDidMount(){
    // this.state.data = await getAllBP();
    let mydata = await getAllBP();
    mydata.forEach(item => {
      item['edit'] = false;      
    })
    this.setState({data:mydata});
  }

  changeFormAdd(e, field){
    let {formAdd} = this.state;
    formAdd[field] =  e.target.value;
    this.setState({formAdd});
  }

  arrayFormAdd(field, size, label){
    let {formAdd} = this.state;
    let myarray = formAdd[field]==null ? new Array(size) : formAdd[field];  
    if(formAdd[field]==null){
      for (let i = 0; i < size; i++) {
        myarray[i] = "null";
      }
    }

    return(
      <Fragment key={field}>
        <Row>
          <Form.Label column sm={1}>
            {label}
          </Form.Label>
          {[...Array(size)].map((x, i) =>
            <Col key={i}>
              <Form.Control 
                style={{minWidth:'100px'}} 
                key={i} 
                onChange={(e)=>{
                  myarray[i] = e.target.value;
                  formAdd[field] = myarray;
                  this.setState({formAdd});
                }}
                placeholder= {`${field.toUpperCase()}${i}`}
              />
            </Col>
          )}
        </Row>
      </Fragment>
    )
  }

  boolFormAdd(field, label){
    return(
      <Fragment key={`${field}boolean`}>
        <Row>
          <Form.Label column sm={1}>
            {label}
          </Form.Label>
          <Col sm={2}>
            <Form.Select onChange={(e)=>{this.changeFormAdd(e,field)}}>
              <option value="null">{Icons.empty}</option>
              <option value="true">{Icons.ok}</option>
              < option value="false">{Icons.wrong}</option>
            </Form.Select>
          </Col>
        </Row>  
      </Fragment>
    )
  }

  fieldFormAdd(field, label, placeholder){
    return(
      <Fragment key={`${field}field`}>
        <Row>
          <Form.Label column sm={1}>
            {label}
          </Form.Label>
          <Col sm={2}>
            <Form.Control 
              type="text" 
              placeholder={placeholder} 
              onChange={(e)=>{this.changeFormAdd(e,field)}}
              />
          </Col>
        </Row>
      </Fragment>
    )
  }


  render(){
    const { user } = this.context;

    return (
      <Fragment>
        <ProtectedComponent>
          {
            user?.role==='public' && 
            <h4>This page is not public. Please contact the admin to approve access.</h4>
          }
          {
            user?.role!=='public' && 
            <Fragment>
              <Row style={{textAlign: 'right'}}>
                <Link to="/baseplate-list">
                  Back to main list
                </Link>
              </Row>
              <h1>Baseplates Lamination</h1>             
              <br />
              <br />
              <TableWithPagination 
                columns={this.columns}
                data={this.state.data}
                defaultPageSize={50}
              />
            </Fragment>
          }
        </ProtectedComponent>
      </Fragment>
    );
  }
}

export default BaseplateLam;