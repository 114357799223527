import React, { Component, Fragment } from 'react';
import ProtectedComponent from './common/ProtectedComponent';
import { getMedia } from '../services/mediaService';
import { getBPbarcode } from '../services/baseplateService';
import { API_BASE } from '../services/apiService';
import { Button, Form, Row, Col } from 'react-bootstrap'
import Icons from './common/Icons';

class BaseplateMedia extends Component {
  constructor(props){
    super(props);
    this.state = {
      id: props.id,
      bar_code: '',
      media : {},
      new_file: null
    }
  }

  async componentDidMount(){
    let data = await getMedia(this.state.id);
    let bar_code = await getBPbarcode(this.state.id);
    if(data){
      this.setState({media:data, bar_code}); 
    }
  }

  
  uploadMedia(id){
    console.log(id)

  }
  
  render(){
    let {id, bar_code, media} = this.state;
    let paddedId = id.toString().padStart(5, '0');

    return (
      <Fragment>
        <ProtectedComponent>
          <h1>Baseplate {bar_code}</h1>
          <Row>
            <Col>
              <Form.Group controlId="mediaFile" className="mb-3">
                <Form.Control 
                  type="file"
                  onChange={(e)=>{
                    console.log(e.target)
                    this.setState({new_file: e.target.value});
                    console.log(this.state)
                  }}
                />
              </Form.Group>
            </Col>
            <Col>
              
              <Button variant='info' onClick={()=>{this.uploadMedia(id)}}>
                Add media {Icons.files}
              </Button>
            </Col>
          </Row>
          <ul>
          {media.length>0 && media.map((file, index) => (
            <li key={index}>
              <a href={`${API_BASE}/media/${paddedId}/${file}`} target="_blank" rel="noopener noreferrer">
                {file}
              </a>
            </li>
          ))}
          </ul>
        </ProtectedComponent>
      </Fragment>
    );
  }
}

export default BaseplateMedia;