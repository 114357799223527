import api, {API_BASE} from './apiService'
import axios from 'axios';
import { getCurrentDateDDMMYY } from './commonService';

export async function getAllBP() {
  const { data } = await api.get(`${API_BASE}/list-bp`, { params :{} });
  return data;
}

export async function getAllLamBP() {
  const { data } = await api.get(`${API_BASE}/list-lam-bp`, { params :{} });
  return data;
}

export async function getAllTapeBP() {
  const { data } = await api.get(`${API_BASE}/list-tape-bp`, { params :{} });
  return data;
}


export async function deleteBP(id) {
  const response = await axios
  .delete(`${API_BASE}/delete-bp/${id}`, {data:{id}, headers: { "Cache-Control": "no-cache" }})
  .catch((error) => console.log("Error: ", error));
  if (response && response.data) {
    return response.data;
  }
}
  
export async function addBP(form, username) {
  let addform = {};
  addform['preqc_user']=username;
  addform['preqc_date']=new Date();
  let heightbare = [];
  let heightlam = [];

  Object.keys(form).forEach((item) => {
    if(form[item]!=null){
      addform[item]=form[item];  
      if(item.startsWith('height_bare')) {
        heightbare.push(parseFloat(form[item]))
      }
      if(item.startsWith('height_lam')) {
        heightlam.push(parseFloat(form[item]))
      }
    }
  })
  
  //calculate flatness_bare_l if height_bare exist
  if(heightbare.length>0){
    let flatness_bare_l = Math.max(...heightbare)-Math.min(...heightbare);
    addform['flatness_bare_l'] = flatness_bare_l.toFixed(3);
  }

  //calculate FLATNESS_LAM if height_lam exist
  if(heightlam.length>0){
    let flatness_lam = Math.max(...heightlam)-Math.min(...heightlam);
    addform['flatness_lam'] = flatness_lam.toFixed(3);
  }
  const { data } = await api.post(`${API_BASE}/add-bp`, {form:addform});
  return data;
}

export async function getBP(id) {
  const { data } = await api.get(`${API_BASE}/get-bp/${id}`, {data:{id}});
  return data;
}

export async function updateBP(id, form, username) {
  let editform = {};
  let isPreQC = false;
  let isLam = false;
  let isPostQC = false;

  Object.keys(form).forEach((item) => {
    if(form[item]!=null){
      if(item==='weight_bare' || item==='thickness' || item==='material_id') isPreQC = true;
      if(item==='tape_id' || item==='kapton_id' || item==='glue_id') isLam = true;
      if(item==='weight_lam' || item==='kapton_align_passed') isPostQC = true;
      editform[item]=form[item]==='' ? null : form[item];
    }
  })

  if(isPreQC){
    editform['preqc_user']=username;
    editform['preqc_date']=new Date();
  }
  if(isLam){
    editform['lam_user']=username;
    editform['lam_date']=new Date();
  }  
  if(isPostQC){
    editform['postqc_user']=username;
    editform['postqc_date']=new Date();
  }

  const { data } = await api.post(`${API_BASE}/update-bp`, {id:id, form:editform});
  if(data['updated_baseplate']) return data['updated_baseplate'];
  return {};
}

export async function getBPbarcode(id) {
  const { data } = await api.get(`${API_BASE}/get-bp-barcode/${id}`, {data:{id}});
  return data;
}

export async function createXMLnewparts(selected) {
  let list = selected.join(',')
  const { data } = await api.get(`${API_BASE}/export-xml-newparts/${list}`, {list:list});
  if(data){
    // Create a Blob from the text content
    const blob = new Blob([data], { type: "text/plain" });
    // Create a temporary URL for the Blob
    const url = window.URL.createObjectURL(blob);
    // Create a temporary <a> element to trigger the download
    const a = document.createElement("a");
    a.href = url;
    let today = getCurrentDateDDMMYY();

    a.download = `KIT_newparts_${today}.xml`;

    // Trigger the click event on the <a> element
    a.click();

    // Cleanup the URL object
    window.URL.revokeObjectURL(url);
  }
  return data;
}

export async function createXMLqcgrade(selected) {
  let list = selected.join(',')
  const { data } = await api.get(`${API_BASE}/export-xml-qcgrade/${list}`, {list:list});
  if(data){
    // Create a Blob from the text content
    const blob = new Blob([data], { type: "text/plain" });
    // Create a temporary URL for the Blob
    const url = window.URL.createObjectURL(blob);
    // Create a temporary <a> element to trigger the download
    const a = document.createElement("a");
    a.href = url;
    let today = getCurrentDateDDMMYY();

    a.download = `KIT_qcgrade_${today}.xml`;

    // Trigger the click event on the <a> element
    a.click();

    // Cleanup the URL object
    window.URL.revokeObjectURL(url);
  }
  return data;
}

export async function getAllKOPs() {
  const { data } = await api.get(`${API_BASE}/list-kop`, { params :{} });
  return data;
}


export async function createXMLqcgradeIsCentralDB() {
  const { data } = await api.get(`${API_BASE}/export-xml-qcgrade-iscentraldb`, {});
  if(data){
    // Create a Blob from the text content
    const blob = new Blob([data], { type: "text/plain" });
    // Create a temporary URL for the Blob
    const url = window.URL.createObjectURL(blob);
    // Create a temporary <a> element to trigger the download
    const a = document.createElement("a");
    a.href = url;
    let today = getCurrentDateDDMMYY();

    a.download = `KIT_qcgrade_${today}.xml`;

    // Trigger the click event on the <a> element
    a.click();

    // Cleanup the URL object
    window.URL.revokeObjectURL(url);
  }
  return data;
}


export async function updateBpColumn(id, variable, value) {
  const {data} = await api.put(`${API_BASE}/update-bpcolumn`, {id, variable, value})
  return data;
}


export async function getpreQCmetrics() {
  const { data } = await api.get(`${API_BASE}/get-preqc-metrics`, {});
  return data;
}

export async function getpostQCmetrics(value) {
  const { data } = await api.get(`${API_BASE}/get-postqc-metrics/${value}`, {value});
  return data;
}


export async function createXMLnewpartsfilter(exportForm) {
  const { data } = await api.get(`${API_BASE}/export-xml-newparts-filter`, { params : {exportForm} });
  if(data){
    // Create a Blob from the text content
    const blob = new Blob([data], { type: "text/plain" });
    // Create a temporary URL for the Blob
    const url = window.URL.createObjectURL(blob);
    // Create a temporary <a> element to trigger the download
    const a = document.createElement("a");
    a.href = url;
    let today = getCurrentDateDDMMYY();

    a.download = `KIT_newparts_${today}_comment.xml`;

    // Trigger the click event on the <a> element
    a.click();

    // Cleanup the URL object
    window.URL.revokeObjectURL(url);
  }
  return data;
}


export async function createXMLqcgradefilter(exportForm) {
  const { data } = await api.get(`${API_BASE}/export-xml-qcgrade-filter`, { params : {exportForm} });
  if(data){
    // Create a Blob from the text content
    const blob = new Blob([data], { type: "text/plain" });
    // Create a temporary URL for the Blob
    const url = window.URL.createObjectURL(blob);
    // Create a temporary <a> element to trigger the download
    const a = document.createElement("a");
    a.href = url;
    let today = getCurrentDateDDMMYY();

    a.download = `KIT_qcgrade_${today}_comment.xml`;

    // Trigger the click event on the <a> element
    a.click();

    // Cleanup the URL object
    window.URL.revokeObjectURL(url);
  }
  return data;
}


export async function updateBPshipment(exportForm) {
  const { data } = await api.get(`${API_BASE}/update-bp-shipment`, { params : {exportForm} });
  return data;
}