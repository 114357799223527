import api, {API_BASE} from './apiService'

export async function getAllGlues() {
  const { data } = await api.get(`${API_BASE}/list-glues`, { params :{} });
  return data;
}

export async function addGlue(formAdd) {
  const { data } = await api.post(`${API_BASE}/add-glue`, {formAdd});
  return data;
}

export async function deleteGlue(id) {
  const {data} = await api.delete(`${API_BASE}/delete-glue/${id}`, {params:{id}})
  return data;
}

export async function updateGlue(id, epoxy_expiry_date) {
  const { data } = await api.put(`${API_BASE}/glue`, {id:id, epoxy_expiry_date:epoxy_expiry_date});
  return data;
}

export async function getSummaryGlue() {
  const { data } = await api.get(`${API_BASE}/list-short-glue`, { params :{} });
  return data;
}