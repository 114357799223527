import api, {API_BASE} from './apiService'

export async function getAllKapton() {
  const { data } = await api.get(`${API_BASE}/list-kapton`, { params :{} });
  return data;
}

export async function addKapton(formAdd) {
  const { data } = await api.post(`${API_BASE}/add-kapton`, {formAdd});
  return data;
}

export async function deleteKapton(id) {
  const {data} = await api.delete(`${API_BASE}/delete-kapton/${id}`, {params:{id}})
  return data;
}

export async function updateKapton(id, variable, value) {
  const {data} = await api.put(`${API_BASE}/update-kapton`, {id, variable, value})
  return data;
}

export async function getSummaryKapton() {
  const { data } = await api.get(`${API_BASE}/list-short-kapton`, { params :{} });
  return data;
}