import api, {API_BASE} from './apiService'
import axios from 'axios';

export async function getAllGlues() {
  const { data } = await api.get(`${API_BASE}/list-glues`, { params :{} });
  return data;
}

export async function addGlue(epoxy_expiry_date) {
  const { data } = await api.post(`${API_BASE}/add-glue`, {epoxy_expiry_date: epoxy_expiry_date});
  return data;
}

export async function deleteGlue(id) {
  const response = await axios
  .delete(`${API_BASE}/delete-glue/${id}`, {data:{id}})
  .catch((error) => console.log("Error: ", error));
  if (response && response.data) {
    return response.data;
  }
}

// Axios.delete(`http://localhost:4000/delete/${nurseID}`, { 
//   data: { nurseID: nurseID}
// });

export async function updateGlue(id, epoxy_expiry_date) {
  const { data } = await api.put(`${API_BASE}/glue`, {id:id, epoxy_expiry_date:epoxy_expiry_date});
  return data;
}