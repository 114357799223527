import React, { createContext, useState, useEffect } from 'react';
import {jwtDecode} from 'jwt-decode';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authToken, setAuthToken] = useState(() => localStorage.getItem('authToken'));
  const [user, setUser] = useState(() => (authToken ? jwtDecode(authToken) : null));

  // Login function to store token and user info
  const login = (token) => {
    localStorage.setItem('authToken', token);
    setAuthToken(token);
    setUser(jwtDecode(token));
  };

  // Logout function to clear token and user info
  const logout = () => {
    localStorage.removeItem('authToken');
    setAuthToken(null);
    setUser(null);
  };

  // Check token expiry on app load
  useEffect(() => {
    if (authToken) {
      const decoded = jwtDecode(authToken);
      if (decoded.exp * 1000 < Date.now()) {
        logout(); // Token expired
      }
    }
  }, [authToken]);

  return (
    <AuthContext.Provider value={{ authToken, user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
