import api, {API_BASE} from './apiService'

export async function getAllMaterial() {
  const { data } = await api.get(`${API_BASE}/list-material`, { params :{} });
  return data;
}

export async function addMaterial(formAdd) {
  const { data } = await api.post(`${API_BASE}/add-material`, {formAdd});
  return data;
}

export async function deleteMaterial(id) {
  const {data} = await api.delete(`${API_BASE}/delete-material/${id}`, {params:{id}})
  return data;
}

export async function updateMaterial(id, variable, value) {
  const {data} = await api.put(`${API_BASE}/update-material`, {id, variable, value})
  return data;
}

export async function getSummaryMaterial() {
    const { data } = await api.get(`${API_BASE}/list-short-material`, { params :{} });
    return data;
}