import React, { Component, Fragment } from 'react';
import { getAllTape, addTape, deleteTape, updateTape } from '../services/tapeService';
import TableWithPagination from './common/TableWithPagination';
import { Button, Modal, Form, Row, Col } from 'react-bootstrap'
import DatePicker from "react-datepicker";
import ProtectedComponent from './common/ProtectedComponent';
import { AuthContext } from './Login/AuthContext';
import { isValidAlphanumeric, isPositiveInteger } from '../services/commonService';
import Icons from './common/Icons';
import "react-datepicker/dist/react-datepicker.css";


class TapeList extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);
    this.state = {
      data : [],
      show : false,
      errors:{},
      formAdd : {
        batch_id : 0,
        expiry_date: new Date(),
        batch_reception_date: new Date(),
        receiver: 'rquishpe',
        geometry:'',
        delivery_count: 0,
        available_count: 0,
        defective_count: 0
      }
    }
  }
  columns = [
    {
      Header:'ID',
      accessor: 'id',
      Cell: (props) => {
        let id = props.cell.value;
        return(
          <Fragment>
            <span>{id} {' '}</span>
            <Button 
              variant="info"
              size='sm'
              disabled={this.context.user.role!=='admin'}
              onClick= {async () => {
                let deleted_id = await deleteTape(id); 
                let new_data =  this.state.data.filter(item => item.id!==deleted_id.id);
                this.setState({data:new_data}); 
              }}
            >
              {Icons.remove}
            </Button>           
          </Fragment>

        );
      }
    },
    {
        Header:'Batch ID',
        accessor:'batch_id'
    },
    {
      Header:'Batch reception date (CEST)',
      accessor: 'batch_reception_date',
      Cell: (props) => {
        let batch_reception_date = props.cell.value!==null ? new Date(props.cell.value).toLocaleDateString("en-US") : null;
        return(
          <Fragment>
            <span>{batch_reception_date}</span>         
          </Fragment>

        );
      }
    },
    {
      Header:'Receiver',
      accessor:'receiver'
    },
    {
      Header:'Expiry date (CEST)',
      accessor: 'expiry_date',
      Cell: (props) => {
        let expiry_date = props.cell.value!==null ? new Date(props.cell.value).toLocaleDateString("en-US") : null;
        return(
          <Fragment>
            <span>{expiry_date}</span>         
          </Fragment>
        );
      }
    },
    {
      Header:'Geometry',
      accessor:'geometry'
    },
    {
      Header:'Version',
      accessor:'version'
    },
    {
      Header:'Delivery count',
      accessor:'delivery_count'
    },
    {
      Header:'Available count',
      accessor:'available_count',
      Cell: (props) => {
        let available_count = props.cell.value;
        let defective_count = props.row.values.defective_count;
        let delivery_count = props.row.values.delivery_count;
        return(
          <Fragment>
            <span>{available_count}</span>
            <Button 
              size='sm' 
              variant='light'
              disabled={Number(available_count)+1>(Number(delivery_count)-Number(defective_count)) || this.context.user.role!=='admin'}
              onClick={async () => {
                let new_count = Number(available_count)+1;
                let new_updated = await updateTape(props.row.values.id, 'available_count',new_count);
                if(new_updated){
                  let new_data = this.state.data
                  new_data[props.row.id].available_count = new_count;
                  this.setState({data:new_data}); 
                  this.updateCellData(props.row.id, props.column.id, new_count)
                }
              }}
            >
              {Icons.up}
            </Button>   
            <Button 
              size='sm' 
              variant='light'
              disabled={(Number(available_count)-1)<0 || this.context.user.role!=='admin'}
              onClick={async () => {
                let new_count = Number(available_count)-1;
                let new_updated = await updateTape(props.row.values.id, 'available_count',new_count);
                if(new_updated){
                  let new_data = this.state.data
                  new_data[props.row.id].available_count = new_count;
                  this.setState({data:new_data}); 
                  this.updateCellData(props.row.id, props.column.id, new_count)
                }
              }}
            >
              {Icons.down}
            </Button>                     
          </Fragment>
        );
      }
    },    
    {
      Header:'Defective count',
      accessor:'defective_count',
      Cell: (props) => {
        let defective_count = props.cell.value;
        let available_count = props.row.values.available_count;
        let delivery_count = props.row.values.delivery_count;
        return(
          <Fragment>
            <span>{defective_count}</span>
            <Button 
              size='sm' 
              variant='light'
              disabled={Number(defective_count)+1>(Number(delivery_count)-Number(available_count)) || this.context.user.role!=='admin'}
              onClick={async () => {
                let new_count = Number(defective_count)+1;
                let new_updated = await updateTape(props.row.values.id, 'defective_count',new_count);
                if(new_updated){
                  let new_data = this.state.data
                  new_data[props.row.id].defective_count = new_count;
                  this.setState({data:new_data}); 
                  this.updateCellData(props.row.id, props.column.id, new_count)
                }
              }}
            >
              {Icons.up}
            </Button>   
            <Button 
              size='sm' 
              variant='light'
              disabled={(Number(defective_count)-1)<0 || this.context.user.role!=='admin'}
              onClick={async () => {
                let new_count = Number(defective_count)-1;
                let new_updated = await updateTape(props.row.values.id, 'defective_count',new_count);
                if(new_updated){
                  let new_data = this.state.data
                  new_data[props.row.id].defective_count = new_count;
                  this.setState({data:new_data}); 
                  this.updateCellData(props.row.id, props.column.id, new_count)
                }
              }}
            >
              {Icons.down}
            </Button>                     
          </Fragment>
        );
      }
    },
    {
      Header:'Comment',
      accessor:'comment'
    },
  ]

  async componentDidMount(){
    let new_data = await getAllTape();
    this.setState({data:new_data});
  }
  

  // ✅ Function to update table data
  updateCellData = (rowIndex, columnId, value) => {
    this.setState((prevState) => {
      const newData = prevState.data.map((row, index) =>
        index === rowIndex ? { ...row, [columnId]: value } : row
      );
      return { data: newData };
    });
  };

  changeFormAdd(e, field){
    let {formAdd} = this.state;
    formAdd[field] =  e.target.value;
    this.setState({formAdd});
  }

  render(){
    const { user } = this.context;
    let {formAdd, errors} = this.state;

    return (
      <Fragment>
        <ProtectedComponent>
          {
            user?.role==='public' && 
            <h4>This page is not public. Please contact the admin to approve access.</h4>
          }
          {
            user?.role!=='public' && 
            <Fragment>
            <h1>Tape List</h1>
            <Button onClick={() => {
              this.setState({show:true});          
            }}>
              Add tape
            </Button>
            <Modal 
              show={this.state.show} 
              onHide={() => {
                this.setState({show:false});  
              } }
              animation={false}
              size='xl'
            >
              <Modal.Header closeButton>
                <Modal.Title>Add new tape</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Form.Label column sm={3}>
                    Batch ID
                  </Form.Label>
                  <Col sm={3}>
                    <Form.Control 
                      type="text" 
                      onChange={(e)=>{
                        //validate value
                        if(isValidAlphanumeric(e.target.value) ){
                          formAdd['batch_id'] = e.target.value;
                          errors['batch_id'] = null;
                          this.setState({ formAdd, errors });
                        }else{
                          errors['batch_id'] = "only alphanumerical";
                          this.setState({errors});
                        }
                      }}
                      isInvalid={!!errors['batch_id']}
                    />
                  </Col>
                </Row>  
                <br />
                <Row>
                  <Form.Label column sm={3}>
                    Batch reception date
                  </Form.Label>
                  <Col sm={3}>
                    <DatePicker 
                      className='form-control form-control-solid w-250px '
                      selected={formAdd['batch_reception_date']} 
                      onChange={(date) => {
                        formAdd['batch_reception_date'] = date;
                        errors['batch_reception_date'] = null;
                        this.setState({ formAdd, errors });
                      }} 
                    />
                  </Col>
                </Row> 
                <br />
                <Row>
                  <Form.Label column sm={3}>
                    Receiver
                  </Form.Label>
                  <Col sm={3}>
                    <Form.Control 
                      type="text" 
                      onChange={(e)=>{
                        //validate value
                        if(isValidAlphanumeric(e.target.value) ){
                          formAdd['receiver'] = e.target.value;
                          errors['receiver'] = null;
                          this.setState({ formAdd, errors });
                        }else{
                          errors['receiver'] = "only alphanumerical";
                          this.setState({errors});
                        }
                      }}
                      isInvalid={!!errors['receiver']}
                    />
                  </Col>
                </Row>  
                <br />
                <Row>
                  <Form.Label column sm={3}>
                    Expiry date
                  </Form.Label>
                  <Col sm={3}>
                    <DatePicker 
                      className='form-control form-control-solid w-250px '
                      selected={formAdd['expiry_date']} 
                      onChange={(date) => {
                        formAdd['expiry_date'] = date;
                        errors['expiry_date'] = null;
                        this.setState({ formAdd, errors });                      
                      }} 
                    />
                  </Col>
                </Row> 
                <br />
                <Row>
                  <Form.Label column sm={3}>
                    Geometry
                  </Form.Label>
                  <Col sm={3}>
                    <Form.Control 
                      type="text" 
                      onChange={(e)=>{
                        //validate value
                        if(isValidAlphanumeric(e.target.value) ){
                          formAdd['geometry'] = e.target.value;
                          errors['geometry'] = null;
                          this.setState({ formAdd, errors });
                        }else{
                          errors['geometry'] = "only alphanumerical";
                          this.setState({errors});
                        }
                      }}
                      isInvalid={!!errors['geometry']}
                    />
                  </Col>
                </Row>  
                <br />
                <Row>
                <Form.Label column sm={3}>
                    Version
                  </Form.Label>
                  <Col sm={3}>
                    <Form.Control 
                      type="text" 
                      onChange={(e)=>{
                        //validate value
                        if(isPositiveInteger(e.target.value) ){
                          formAdd['version'] = e.target.value;
                          errors['version'] = null;
                          this.setState({ formAdd, errors });
                        }else{
                          errors['version'] = "only integer";
                          this.setState({errors});
                        }
                      }}
                      isInvalid={!!errors['version']}
                    />
                  </Col>
                </Row>
                <br />
                <Row>
                  <Form.Label column sm={3}>
                    Delivery count
                  </Form.Label>
                  <Col sm={3}>
                    <Form.Control 
                      type="text" 
                      onChange={(e)=>{
                        //validate value
                        if(isPositiveInteger(e.target.value) ){
                          formAdd['delivery_count'] = e.target.value;
                          errors['delivery_count'] = null;
                          this.setState({ formAdd, errors });
                        }else{
                          errors['delivery_count'] = "only alphanumerical";
                          this.setState({errors});
                        }
                      }}
                      isInvalid={!!errors['delivery_count']}
                    />
                  </Col>
                </Row>  
                <br />
                <Row>
                  <Form.Label column sm={3}>
                    Available count
                  </Form.Label>
                  <Col sm={3}>
                    <Form.Control 
                      type="text" 
                      onChange={(e)=>{
                        //validate value
                        if(isPositiveInteger(e.target.value) ){
                          formAdd['available_count'] = e.target.value;
                          errors['available_count'] = null;
                          this.setState({ formAdd, errors });
                        }else{
                          errors['available_count'] = "only alphanumerical";
                          this.setState({errors});
                        }
                      }}
                      isInvalid={!!errors['available_count']}
                    />
                  </Col>
                </Row>  
                <br /> 
                <Row>
                  <Form.Label column sm={3}>
                    Defective count
                  </Form.Label>
                  <Col sm={3}>
                    <Form.Control 
                      type="text" 
                      onChange={(e)=>{
                        //validate value
                        if(isPositiveInteger(e.target.value) ){
                          formAdd['defective_count'] = e.target.value;
                          errors['defective_count'] = null;
                          this.setState({ formAdd, errors });
                        }else{
                          errors['defective_count'] = "only alphanumerical";
                          this.setState({errors});
                        }
                      }}
                      isInvalid={!!errors['defective_count']}
                    />
                  </Col>
                </Row>                  
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => {
                this.setState({show:false});  
              }}>
                  Close
                </Button>
                <Button 
                  variant="primary" 
                  onClick={async () => {
                    let new_item = await addTape(this.state.formAdd);
                    let new_data = this.state.data;
                    new_data.push(new_item);     
                    this.setState({show: false, data: new_data});  
                    window.location.reload();      
                  }}
                >
                  Add
                </Button>
              </Modal.Footer>
            </Modal>
            <br />
            <br />
            <TableWithPagination 
              columns={this.columns}
              data={this.state.data}
              defaultPageSize={20}
            />
          </Fragment>
          }
        </ProtectedComponent>
      </Fragment>
    );
  }
}

export default TapeList;

