import React, { Component, Fragment } from 'react';
import Icons from './common/Icons';
import ProtectedComponent from './common/ProtectedComponent';
import { getAllMaterial, addMaterial, deleteMaterial } from '../services/materialService';
import { isValidDecimal, isValidAlphanumeric } from '../services/commonService';
import { AuthContext } from './Login/AuthContext';
import { Button, Modal, Form, Row, Col } from 'react-bootstrap'
import TableWithPagination from './common/TableWithPagination';
import Select from "react-select";

class MaterialList extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);
    this.state = {
      data : [],
      show : false,
      errors:{},
      formAdd : {
        batch_id : 0,
        type: null,
        cu_composition: null,
      },
      type_options: [{value:'CuW', label:'CuW'},{value:'Ti', label:'Ti'}]

    }
  }

  columns = [
    {
      Header:'ID',
      accessor: 'id',
      Cell: (props) => {
        let id = props.cell.value;
        return(
          <Fragment>
            <span>{id} {' '}</span>
            <Button 
              variant="info"
              size='sm'
              disabled={this.context.user.role!=='admin'}
              onClick= {async () => {
                let deleted_id = await deleteMaterial(id); 
                if(deleted_id){
                  let new_data =  this.state.data.filter(item => item.id!==deleted_id.id);
                  this.setState({data:new_data}); 
                }
              }}
            >
              {Icons.remove}
            </Button>           
          </Fragment>

        );
      }
    },
    {
      Header:'Batch ID',
      accessor: 'batch_id',
    },
    {
      Header:'Type',
      accessor: 'type',
    },
    {
      Header:'Cu % composition',
      accessor: 'cu_composition',
    },  
  ]
  
  async componentDidMount(){
    let new_data = await getAllMaterial();
    this.setState({data:new_data});
  }
  // ✅ Function to update table data
  updateCellData = (rowIndex, columnId, value) => {
    this.setState((prevState) => {
      const newData = prevState.data.map((row, index) =>
        index === rowIndex ? { ...row, [columnId]: value } : row
      );
      return { data: newData };
    });
  };
  render(){
    const { user } = this.context;
    let {formAdd, errors} = this.state;

    return (
      <Fragment>
        <ProtectedComponent>
          {
            user?.role==='public' && 
            <h4>This page is not public. Please contact the admin to approve access.</h4>
          }
          {
            user?.role!=='public' && 
            <Fragment>
            <h1>Material List</h1>
            <Button onClick={() => {
              this.setState({show:true});          
            }}>
              Add material
            </Button>
            <Modal 
              show={this.state.show} 
              onHide={() => {
                this.setState({show:false});  
              } }
              animation={false}
              size='xl'
            >
              <Modal.Header closeButton>
                <Modal.Title>Add new material</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Form.Label column sm={3}>
                    Batch ID from vendor
                  </Form.Label>
                  <Col sm={3}>
                    <Form.Control 
                      type="text" 
                      onChange={(e)=>{
                        //validate value
                        if(isValidAlphanumeric(e.target.value) ){
                          formAdd['batch_id'] = e.target.value;
                          errors['batch_id'] = null;
                          this.setState({ formAdd, errors });
                        }else{
                          errors['batch_id'] = "only alphanumerical";
                          this.setState({errors});
                        }
                      }}
                      isInvalid={!!errors['batch_id']}
                    />
                  </Col>
                </Row>  
                <br />
                <Row>
                  <Form.Label column sm={3}>
                    Type
                  </Form.Label>
                  <Col sm={3}>
                    <Select 
                      onChange={async(e) => {
                        formAdd['type'] = e.value;
                        this.setState({ formAdd});
                      }}
                      options={this.state.type_options}
                    />
                  </Col>
                </Row>  
                <br />
                <Row>
                  <Form.Label column sm={3}>
                    Cu % composition
                  </Form.Label>
                  <Col sm={3}>
                    <Form.Control 
                      type="text" 
                      onChange={(e)=>{
                        //validate value
                        if(isValidDecimal(e.target.value) ){
                          formAdd['cu_composition'] = e.target.value;
                          errors['cu_composition'] = null;
                          this.setState({ formAdd, errors });
                        }else{
                          errors['cu_composition'] = "only decimal";
                          this.setState({errors});
                        }
                      }}
                      isInvalid={!!errors['cu_composition']}
                    />
                  </Col>
                </Row>                  
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => {
                this.setState({show:false});  
              }}>
                  Close
                </Button>
                <Button 
                  variant="primary" 
                  onClick={async () => {
                    let new_item = await addMaterial(this.state.formAdd);
                    let new_data = this.state.data;
                    new_data.push(new_item);     
                    this.setState({show: false, data: new_data});  
                    window.location.reload();      
                  }}
                >
                  Add
                </Button>
              </Modal.Footer>
            </Modal>
            <br />
            <br />
            <TableWithPagination 
              columns={this.columns}
              data={this.state.data}
              defaultPageSize={20}
            />
          </Fragment>
          }
        </ProtectedComponent>
      </Fragment>
    );
  }
}

export default MaterialList;