import React, { Component, Fragment } from 'react';
import { Button, Modal, Form, Row, Col, Spinner } from 'react-bootstrap'
import { getBP, updateBP } from '../services/baseplateService';
import { isDef } from '../services/commonService';
import { getSummaryKapton } from '../services/kaptonService';
import { getSummaryGlue } from '../services/glueService';
import Select from "react-select";

class BaseplateEditLam extends Component {
  constructor(props){
    super(props);
    this.state = {
      username : props.username,
      userrole : props.userrole,
      loading : true,
      isOpen: props.isOpen,
      id: props.id,
      data: {},
      kapton_options : {},
      glue_options : {},
      formEdit: {
        kapton_id: null,
        glue_id: null,
      },
      isModified: false,
      errors:{}
    }
  }

  async componentDidMount(){
    if(this.state.isOpen){
      let data = await getBP(this.state.id);
      let kopt = await getSummaryKapton();
      let gopt = await getSummaryGlue();
      if(data){
        this.setState({data: data[0], kapton_options: kopt, glue_options: gopt, loading:false }); 
      }
    }
  }

  closeModal = () => {
		this.resetForm();
		isDef(this.props.callback) && this.props.callback(false);
	};

  resetForm = () =>{
		let {formEdit} = this.state;
    formEdit= {
      kapton_id: null,
      glue_id: null,
    };
		this.setState({formEdit, isModified:false});
	}

  changeFormEdit(e, field){
    let {formEdit, data} = this.state;
    formEdit[field] =  e.target.value;
    data[field] = e.target.value;
    this.setState({formEdit, data, isModified:true }); 
  }

  render(){
    if (this.state.loading) return <Spinner animation="border" variant="info" />
    let {formEdit,data,isOpen, errors} = this.state;

    return (
      <Fragment>
        <Modal
          show={isOpen} 
          onHide={() => {
            this.setState({ isOpen:false });  
          } }
          animation={false}
          size='xl'
          aria-labelledby="contained-modal-title-vcenter"
          scrollable
          centered
        >
          <Modal.Header>
            <Modal.Title>
              Edit baseplate {data.bar_code === null ? data.workshop_serial : data.bar_code}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form style={{ minWidth:'2000px'}}>
              <Row>
                <Form.Label column sm={1}>
                  KAPTON
                </Form.Label>
                <Col sm={2}>
                  <Select 
                    value={{value:data.kapton_id, label: data.kapton_description}} 
                    onChange={async(e) => {
                      formEdit['kapton_id'] = e.value;
                      data['kapton_id'] = e.value;
                      data['kapton_description'] = e.label;
                      this.setState({data, formEdit, isModified:true});
                    }}
                    options={this.state.kapton_options}
                  />
                </Col>
              </Row>  
              <br />
              <Row>
                <Form.Label column sm={1}>
                  GLUE
                </Form.Label>
                <Col sm={2}>
                  <Select 
                    value={{value:data.glue_id, label: data.epoxy_expiry_date}} 
                    onChange={async(e) => {
                      formEdit['glue_id'] = e.value;
                      data['glue_id'] = e.value;
                      data['epoxy_expiry_date'] = e.label;
                      this.setState({data, formEdit, isModified:true});
                    }}
                    options={this.state.glue_options}
                  />
                </Col>
              </Row>  
            </Form>
          </Modal.Body>
          <Modal.Footer>            
            <Button 
              variant="secondary"
              onClick={() => this.state.isModified ? ((window.confirm('Close without saving?')) && this.closeModal()) : this.closeModal() }
            >
              Close
            </Button>
            <Button 
              variant="primary" 
              disabled={!this.state.isModified}
              onClick={async () => {
                let updated_bp = await updateBP(this.state.id, this.state.formEdit, this.state.username);   
                if(updated_bp){
                  //remove bp from list since kapton* glue* is not null
                  isDef(this.props.callbackbps) && this.props.callbackbps(this.state.id);
                  this.resetForm();
                }
                this.closeModal();
              }}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    );
  }
}

export default BaseplateEditLam;