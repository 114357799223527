import React, { Component, Fragment } from 'react';
import { Button, Modal, Form, Row, Col, Spinner } from 'react-bootstrap'
import { getBP, updateBP } from '../services/baseplateService';
import Icons from './common/Icons';
import { isValidAlphanumeric, isValidDecimal } from '../services/commonService';


class BaseplateEdit extends Component {
  constructor(props){
    super(props);
    this.state = {
      username : props.username,
      loading : true,
      isOpen: props.isOpen,
      id: props.id,
      data: {},
      formEdit: {
        barcode : null,
        weight_bare: null,
        weight_lam: null,
        thickness: null,
        height_bare: null,
        height_lam: null,
        flatness_grade: null,
        width_edge: null,
        width_corner: null,
        radius: null,
        dist_hole_slot: null,
        diameter_hole_passed: null,
        length_of_slot: null,
        width_lot_passed: null,
        notch_size_passed: null,
        tab_width: null,
        tab_height: null,
        kapton_align_passed: null,
        test_date: null,
        tolerance_grade: null,
        comment: null,
      },
      errors:{}

    }

  }

  async componentDidMount(){
    if(this.state.isOpen){
      let data = await getBP(this.state.id);
      if(data){
        this.setState({data: data[0], loading:false }); 
      }
    }
  }


  changeFormEdit(e, field){
    let {formEdit, data} = this.state;
    formEdit[field] =  e.target.value;
    data[field] = e.target.value;
    this.setState({formEdit, data }); 
  }

  textFormEdit(field, label){ 
    let {data, errors, formEdit} = this.state; 
    return(
      <Fragment key={`${field}field`}>
        <Row>
          <Form.Label column sm={1}>
            {label}
          </Form.Label>
          <Col sm={2}>
            <Form.Control 
              type="text" 
              value={data[field] ? data[field] : ''} 
              onChange={(e)=>{
                //validate value
                if(isValidAlphanumeric(e.target.value) ){
                  formEdit[field] = e.target.value;
                  data[field] = e.target.value;
                  errors[field] = null;
                  this.setState({ formEdit, data, errors });
                }else{
                  errors[field] = "only alphanumerical characters";
                  this.setState({errors});
                }
              }}
              isInvalid={!!errors[field]}
            />
            <Form.Control.Feedback type="invalid">
              {this.state.errors[field]}
            </Form.Control.Feedback>
          </Col>
        </Row>
      </Fragment>
    )
  }


  decimalFormEdit(field, label){ 
    let {data, errors, formEdit} = this.state; 
    return(
      <Fragment key={`${field}field`}>
        <Row>
          <Form.Label column sm={1}>
            {label}
          </Form.Label>
          <Col sm={2}>
            <Form.Control 
              type="text" 
              value={data[field] ? data[field] : ''} 
              onChange={(e)=>{
                //validate value
                if(isValidDecimal(e.target.value) ){
                  formEdit[field] = e.target.value;
                  data[field] = e.target.value;
                  errors[field] = null;
                  this.setState({ formEdit, data, errors });
                }else{
                  errors[field] = "only decimal";
                  this.setState({errors});
                }
              }}
              isInvalid={!!errors[field]}
            />
            <Form.Control.Feedback type="invalid">
              {this.state.errors[field]}
            </Form.Control.Feedback>
          </Col>
        </Row>
      </Fragment>
    )
  }

  fieldFormRead(field, label, value){
    return(
      <Fragment key={`${field}field`}>
        <Row>
          <Form.Label column sm={1}>
            {label}
          </Form.Label>
          <Col sm={2}>
            <Form.Control 
              type="text" 
              value={value} 
              readOnly
              />
          </Col>
        </Row>
      </Fragment>
    )
  }


  arrayFormEdit(field, size, label){
    let {formEdit, data, errors} = this.state;
    let myarray = data[field]==null ? new Array(size) : data[field];  
    if(data[field]==null){
      for (let i = 0; i < size; i++) {
        myarray[i] = '';
      }
    }

    return(
      <Fragment key={field}>
        <Row>
          <Form.Label column sm={1}>
            {label}
          </Form.Label>
          {[...Array(size)].map((x, i) =>
            <Col key={i}>
              <Form.Control 
                style={{minWidth:'100px'}} 
                key={i} 
                value={myarray[i]}
                onChange={(e)=>{
                  //validate value
                  if(isValidDecimal(e.target.value) ){
                    myarray[i] =e.target.value;
                    formEdit[field] = myarray;
                    data[field] = myarray;
                    errors[field+i] = null;
                    this.setState({ formEdit, data });
                  }else{
                    errors[field+i] = "only decimal numbers accepted";
                    this.setState({errors});
                  }
                }}
                isInvalid={!!errors[field+i]}
              />
              <Form.Control.Feedback type="invalid">
                {errors[field+i]}
              </Form.Control.Feedback>
            </Col>
          )}
        </Row>
      </Fragment>
    )
  }

  boolFormEdit(field, label){
    let {data} = this.state;
    return(
      <Fragment key={`${field}boolean`}>
        <Row>
          <Form.Label column sm={1}>
            {label}
          </Form.Label>
          <Col sm={2}>
            <Form.Select 
              value={data[field] ? data[field] : "null"} 
              onChange={(e)=>{
                let {formEdit} = this.state;
                formEdit[field] =  e.target.value;
                data[field] = e.target.value; 
                this.setState({ formEdit, data }); 
              }}  
            >
              <option value="null">{Icons.empty}</option>
              <option value="true">{Icons.ok}</option>
              < option value="false">{Icons.wrong}</option>
            </Form.Select>
          </Col>
        </Row>  
      </Fragment>
    )
  }
  
  render(){
    if (this.state.loading) return <Spinner animation="border" variant="info" />

    return (
      <Fragment>
        <Modal
          show={this.state.isOpen} 
          onHide={() => {
            // this.state.isOpen = false;
            this.setState({ isOpen:false });  
          } }
          animation={false}
          size='xl'
          aria-labelledby="contained-modal-title-vcenter"
          scrollable
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit baseplate</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form style={{ minWidth:'2000px'}}>
              {this.textFormEdit('bar_code','BAR CODE')}
              <br />
              <br />
              {this.decimalFormEdit('weight_bare', 'WEIGHT BARE')}
              <br />
              {this.decimalFormEdit('weight_lam', 'WEIGHT LAM')}
              <br />
              {this.decimalFormEdit('thickness', 'THICKNESS')}
              <br />
              {this.arrayFormEdit('height_bare',10,'HEIGHT BARE')}
              <br />
              {this.arrayFormEdit('height_lam',10,'HEIGHT LAM')}
              <br />
              <Row>
                <Form.Label column sm={1}>
                  FLATNESS GRADE
                </Form.Label>
                <Col sm={2}>
                  <Form.Select onChange={(e)=>{this.changeFormEdit(e,'flatness_grade')}}>
                    <option value="null">None</option>
                    <option value="green">green</option>
                    <option value="orange">orange</option>
                    <option value="red">red</option>
                  </Form.Select>
                </Col>
              </Row>  
              <br />
              {this.arrayFormEdit('width_edge',3,'WIDTH EDGE')}  
              <br />
              {this.arrayFormEdit('width_corner',3,'WIDTH CORNER')}    
              <br />
              {this.arrayFormEdit('radius',6,'RADIUS')}      
              <br />
              {this.decimalFormEdit('dist_hole_slot', 'DISTANCE FROM HOLE TO SLOT','')}
              <br />
              {this.boolFormEdit('diameter_hole_passed','DIAMETER HOLE PASSED')}
              <br />
              {this.decimalFormEdit('length_slot', 'LENGTH OF SLOT','')}
              <br />
              {this.boolFormEdit('width_slot_passed','WIDTH SLOT PASSED')}
              <br />
              {this.boolFormEdit('notch_size_passed','NOTCH SIZE PASSED')}
              <br />
              {this.arrayFormEdit('tab_width',3,'TAB WIDTH')}  
              <br />
              {this.arrayFormEdit('tab_height',3,'TAB HEIGHT')} 
              <br />
              {this.boolFormEdit('kapton_align_passed','KAPTON ALIGN PASSED')}
              <br />
              <Row>
                <Form.Label column sm={1}>
                  TOLERANCE GRADE
                </Form.Label>
                <Col sm={2}>
                  <Form.Select onChange={(e)=>{this.changeFormEdit(e,'tolerance_grade')}}>
                    <option value="null">None</option>
                    <option value="green">green</option>
                    <option value="orange">orange</option>
                    <option value="red">red</option>
                  </Form.Select>
                </Col>
              </Row> 
              <br />
              <Row>
                <Form.Label column sm={1}>
                  COMMENT
                </Form.Label>
                <Col sm={3}>
                  <Form.Control 
                    as="textarea" 
                    rows={3}
                    onChange={(e)=>{this.changeFormEdit(e,'comment')}}
                    />
                </Col>
              </Row>
            </Form>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => {
            this.setState({ isOpen:false });  
          }}>
              Close
            </Button>
            <Button 
              variant="primary" 
              onClick={async () => {
                await updateBP(this.state.id, this.state.formEdit, this.state.username);   
                this.setState({isOpen: false});  
                window.location.reload();      
              }}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    );
  }
}

export default BaseplateEdit;