import api, {API_BASE} from './apiService'
import axios from 'axios';
import { getCurrentDateDDMMYY } from './commonService';

const arrayfields = ['height_bare', 'height_lam', 'width_edge', 'width_corner', 'radius', 'tab_width', 'tab_height']

export async function getAllBP() {
  const { data } = await api.get(`${API_BASE}/list-bp`, { params :{} });
  return data;
}

export async function deleteBP(id) {
  const response = await axios
  .delete(`${API_BASE}/delete-bp/${id}`, {data:{id}})
  .catch((error) => console.log("Error: ", error));
  if (response && response.data) {
    return response.data;
  }
}
  
export async function addBP(form, username) {
  // let addform = form;
  let addform = {};
  addform['preqc_user']=username;
  addform['preqc_date']=new Date();
  Object.keys(form).forEach((item) => {
    if(form[item]!=null){
      if(arrayfields.includes(item)) {
        addform[item]='{'+form[item].toString()+'}';
      }else{
        addform[item]=form[item];
      }
    }
  })
  const { data } = await api.post(`${API_BASE}/add-bp`, {form:addform});
  return data;
}

export async function getBP(id) {
  const { data } = await api.get(`${API_BASE}/get-bp/${id}`, {data:{id}});
  return data;
}


export async function updateBP(id, form, username) {
  let editform = {};
  let lamkeys = ['weight_lam','height_lam','flatness_lam'];
  let isLam = false;

  Object.keys(form).forEach((item) => {
    if(form[item]!=null){
      if(lamkeys.includes(item)) isLam = true;
      if(arrayfields.includes(item)) {
        editform[item]='{'+form[item].toString()+'}';
      }else{
        editform[item]=form[item];
      }
    }
  })

  if(isLam){
    editform['lam_user']=username;
    editform['lam_date']=new Date();
  }

  const { data } = await api.post(`${API_BASE}/update-bp`, {id:id, form:editform});
  return data;
}

export async function getBPbarcode(id) {
  const { data } = await api.get(`${API_BASE}/get-bp-barcode/${id}`, {data:{id}});
  return data;
}

export async function createXMLnewparts(selected) {
  let list = selected.join(',')
  const { data } = await api.get(`${API_BASE}/export-xml-newparts/${list}`, {list:list});
  if(data){
    // Create a Blob from the text content
    const blob = new Blob([data], { type: "text/plain" });
    // Create a temporary URL for the Blob
    const url = window.URL.createObjectURL(blob);
    // Create a temporary <a> element to trigger the download
    const a = document.createElement("a");
    a.href = url;
    let today = getCurrentDateDDMMYY();

    a.download = `KIT_newparts_${today}.xml`;

    // Trigger the click event on the <a> element
    a.click();

    // Cleanup the URL object
    window.URL.revokeObjectURL(url);
  }
  return data;
}

export async function createXMLqcgrade(selected) {
  let list = selected.join(',')
  const { data } = await api.get(`${API_BASE}/export-xml-qcgrade/${list}`, {list:list});
  if(data){
    // Create a Blob from the text content
    const blob = new Blob([data], { type: "text/plain" });
    // Create a temporary URL for the Blob
    const url = window.URL.createObjectURL(blob);
    // Create a temporary <a> element to trigger the download
    const a = document.createElement("a");
    a.href = url;
    let today = getCurrentDateDDMMYY();

    a.download = `KIT_qcgrade_${today}.xml`;

    // Trigger the click event on the <a> element
    a.click();

    // Cleanup the URL object
    window.URL.revokeObjectURL(url);
  }
  return data;
}