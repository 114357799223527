import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../Login/AuthContext';

const ProtectedComponent = ({ children }) => {
  const { authToken } = useContext(AuthContext);

  // Redirect to login if not authenticated
  return authToken ? children : <Navigate to="/login" />;
};

export default ProtectedComponent;