import React, { Component, Fragment } from 'react';
import { AuthContext } from './Login/AuthContext';
import ProtectedComponent from './common/ProtectedComponent';
import { getpreQCmetrics, getpostQCmetrics } from '../services/baseplateService';
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Tab, Tabs, Spinner } from 'react-bootstrap';
import { stringtypes } from '../services/commonService';

class Metrics extends Component {
  static contextType = AuthContext;
  constructor(props){
    super(props);
    this.state = {
      preqc_data : [],
      postqc_data : [],
      loading : true,

    }
  }

  async componentDidMount(){
    const { user } = this.context;
    if(user){
      let predata = await getpreQCmetrics();
      let new_data = [];
      if(predata){
        predata.forEach(item=>{
          let new_item = {
            date: item['week_start'].substring(0,10),
            red: item['red']==null ? 0 : Number(item['red']),
            orange: item['orange']==null ? 0 : Number(item['orange']),
            green: item['green']==null ? 0 : Number(item['green']),
          }
          new_data.push(new_item);
        })
      }
      
      const promises = stringtypes.map(async (stype) => {
        const idata = await getpostQCmetrics(stype.name);
        let new_idata = [];
        if(idata){
          idata.forEach(item=>{
            let new_item = {
              date: item['week_start'].substring(0,10),
              red: item['red']==null ? 0 : Number(item['red']),
              orange: item['orange']==null ? 0 : Number(item['orange']),
              green: item['green']==null ? 0 : Number(item['green']),
            }
            new_idata.push(new_item);
          })
        }
        return JSON.stringify({label:stype.label, data: new_idata});
      });
      
      // Wait for all promises to fulfill first, then log
      // the usernames
      const new_postqc_data = await Promise.all(promises);

      this.setState({preqc_data:new_data, postqc_data:new_postqc_data, loading:false});
    }
  }

  render(){
    // const { user } = this.context;
    if (this.state.loading) {
      return <Spinner animation="border" variant="info" />
    }else{
      let {preqc_data, postqc_data} = this.state;
      let obj_postqc_data = [];
      postqc_data.forEach(item=> {
        let obj = JSON.parse(item);
        obj_postqc_data.push(obj)
      })

      return (
        <Fragment>
          <ProtectedComponent>
            <Tabs
              defaultActiveKey="preqc"
              transition={false}
              id="noanim-tab-example"
              className="mb-3"
            >
              <Tab eventKey="preqc" title="Pre QC" key='preqc_tab'>
                <br />
                <ResponsiveContainer height={400} minWidth={500} key='chartqc'>
                  <BarChart
                    width={500}
                    height={300}
                    data={preqc_data}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date"  />
                    <YAxis label={{ value: 'Number of base plates', angle: -90, position: 'insideLeft' }} />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="green" fill="#82ca9d" activeBar={<Rectangle fill="green" stroke="blue" />} />
                    <Bar dataKey="orange" fill="#f59542" activeBar={<Rectangle fill="orange" stroke="gold" />} />
                    <Bar dataKey="red" fill="#e81717" activeBar={<Rectangle fill="red" stroke="pink" />} />
                  </BarChart>
                </ResponsiveContainer>
              </Tab>
              <Tab eventKey="postqc" title="Post QC" key='postqc_tab'>
                {obj_postqc_data.map(item =>{
                    return(
                    <Fragment key={item.label}>
                      <br />
                      <h3>{item.label}</h3>
                      {item.data.length>0 &&
                        <ResponsiveContainer height={400} minWidth={500}>
                          <BarChart
                            width={500}
                            height={300}
                            data={item.data}
                            margin={{
                              top: 5,
                              right: 30,
                              left: 20,
                              bottom: 5,
                            }}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date"  />
                            <YAxis label={{ value: 'Number of base plates', angle: -90, position: 'insideLeft' }} />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="green" fill="#82ca9d" activeBar={<Rectangle fill="green" stroke="blue" />} />
                            <Bar dataKey="orange" fill="#f59542" activeBar={<Rectangle fill="orange" stroke="gold" />} />
                            <Bar dataKey="red" fill="#e81717" activeBar={<Rectangle fill="red" stroke="pink" />} />
                          </BarChart>
                        </ResponsiveContainer>
                      }
                      <br />
                    </Fragment>
                    )
                  })
                }
              </Tab>
            </Tabs>
          </ProtectedComponent>
        </Fragment>
      );
    }

    }

}
export default Metrics;

