import React, { Component, Fragment } from 'react';
import { Button, Modal, Form, Row, Col } from 'react-bootstrap'
import { addBP } from '../services/baseplateService';
import { isValidDecimal, isPositiveInteger , isDef} from '../services/commonService';
import { getSummaryMaterial } from '../services/materialService';
import { getSummaryGlue } from '../services/glueService';
import { getSummaryKapton } from '../services/kaptonService';
import Select from "react-select";

class BaseplateAdd extends Component {
  constructor(props){
    super(props);
    this.state = {
      isOpen: props.isOpen,
      user: props.user,
      errors:{},
      kapton_options : {},
      glue_options : {},
      material_options : {},
      isModified: false,
      formAdd: {
        workshop_serial: null,
        weight_bare: null,
        weight_lam: null,
        thickness: null,
        comment: null,
        kapton_id: null,
        glue_id: null,
        material_id: null
      }
    }
  }

  async componentDidMount(){
    //call kind of parts
    if(this.state.isOpen){
      let kopt = await getSummaryKapton();
      let mopt = await getSummaryMaterial();
      let gopt = await getSummaryGlue();
      this.setState({kapton_options: kopt, material_options: mopt, glue_options: gopt, loading:false }); 
    }
  }

  closeModal = () => {
    this.resetForm();
    isDef(this.props.callback) && this.props.callback(false);
  };

  resetForm = () =>{
		let {formAdd} = this.state;
    formAdd= {
      workshop_serial: null,
      weight_bare: null,
      weight_lam: null,
      thickness: null,
      comment: null,
      material_id: null,
    };
		this.setState({formAdd, isModified:false});
	}

  changeFormAdd(e, field){
    let {formAdd} = this.state;
    formAdd[field] =  e.target.value;
    this.setState({formAdd});
  }

  fieldFormAddDecimal(field, label, placeholder){
    let {formAdd, errors} = this.state;
    return(
      <Fragment key={`${field}field`}>
        <Row>
          <Form.Label column sm={1}>
            {label}
          </Form.Label>
          <Col sm={2}>
            <Form.Control 
              type="text" 
              placeholder={placeholder} 
              onChange={(e)=>{
                //validate value
                if(isValidDecimal(e.target.value) ){
                  formAdd[field] = e.target.value;
                  errors[field] = null;
                  this.setState({ formAdd, errors });
                }else{
                  errors[field] = "only decimal";
                  this.setState({errors});
                }
              }}
              isInvalid={!!errors[field]}
            />
            <Form.Control.Feedback type="invalid">
              {this.state.errors[field]}
            </Form.Control.Feedback>
          </Col>
        </Row>
      </Fragment>
    )
  }


  fieldFormAddInteger(field, label, placeholder){
    let {formAdd, errors} = this.state;
    return(
      <Fragment key={`${field}field`}>
        <Row>
          <Form.Label column sm={1}>
            {label}
          </Form.Label>
          <Col sm={2}>
            <Form.Control 
              type="text" 
              placeholder={placeholder} 
              onChange={(e)=>{
                //validate value
                if(isPositiveInteger(e.target.value) ){
                  formAdd[field] = e.target.value;
                  errors[field] = null;
                  this.setState({ formAdd, errors });
                }else{
                  errors[field] = "only integer > 0";
                  this.setState({errors});
                }
              }}
              isInvalid={!!errors[field]}
            />
            <Form.Control.Feedback type="invalid">
              {this.state.errors[field]}
            </Form.Control.Feedback>
          </Col>
        </Row>
      </Fragment>
    )
  }

  render(){
    const {formAdd} = this.state;
    return(
      <Fragment>
        <Modal 
          show={this.state.isOpen} 
          onHide={() => {
            this.setState({isOpen:false});  
          } }
          animation={false}
          size='xl'
          aria-labelledby="contained-modal-title-vcenter"
          scrollable
          centered
        >
          <Modal.Header>
            <Modal.Title>Add new baseplate</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form style={{ minWidth:'2000px'}}>
              {this.fieldFormAddInteger('workshop_serial', 'WORKSHOP SERIAL','')}
              <br />
              {this.fieldFormAddDecimal('weight_bare', 'WEIGHT BARE','')}
              <br />
              {this.fieldFormAddDecimal('thickness', 'THICKNESS','')}
              <br />
              <Row>
                <Form.Label column sm={1}>
                  KAPTON
                </Form.Label>
                <Col sm={2}>
                  <Select 
                    onChange={async(e) => {
                      formAdd['kapton_id'] = e.value;
                      this.setState({formAdd});
                    }}
                    options={this.state.kapton_options}
                  />
                </Col>
              </Row> 
              <br />
              <Row>
                <Form.Label column sm={1}>
                  GLUE
                </Form.Label>
                <Col sm={2}>
                  <Select 
                    onChange={async(e) => {
                      formAdd['glue_id'] = e.value;
                      this.setState({formAdd});
                    }}
                    options={this.state.glue_options}
                  />
                </Col>
              </Row> 
              <br />
              <Row>
                <Form.Label column sm={1}>
                  MATERIAL
                </Form.Label>
                <Col sm={2}>
                  <Select 
                    onChange={async(e) => {
                      formAdd['material_id'] = e.value;
                      this.setState({formAdd});
                    }}
                    options={this.state.material_options}
                  />
                </Col>
              </Row> 
              <br />
              <Row>
                <Form.Label column sm={1}>
                  COMMENT
                </Form.Label>
                <Col sm={3}>
                  <Form.Control 
                    as="textarea" 
                    rows={3}
                    onChange={(e)=>{this.changeFormAdd(e,'comment')}}
                    />
                </Col>
              </Row>

            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button 
              variant="secondary"
              onClick={() => this.state.isModified ? ((window.confirm('Close without saving?')) && this.closeModal()) : this.closeModal() }
            >
              Close
            </Button>
            <Button 
              variant="primary" 
              onClick={async () => {
                let new_item = await addBP(this.state.formAdd, this.state.user.username);
                if (new_item){
                  if(isDef(new_item['kapton_id'])){
                    let option = this.state.kapton_options.filter(item => item.value == new_item['kapton_id'])
                    if(option.length>0){
                      new_item['kapton_description']=option[0]['label'];
                    }
                  }
                  if(isDef(new_item['glue_id'])){
                    let option = this.state.glue_options.filter(item => item.value == new_item['glue_id'])
                    if(option.length>0){
                      new_item['epoxy_expiry_date']=option[0]['label'];
                    }
                  }
                  if(isDef(new_item['material_id'])){
                    let option = this.state.glue_options.filter(item => item.value == new_item['material_id'])
                    if(option.length>0){
                      new_item['material_description']=option[0]['label'];
                    }
                  }

                  isDef(this.props.callbackbps) && this.props.callbackbps(new_item);
                  this.resetForm();
                  this.closeModal();
                }
                this.closeModal();

              }}
            >
              Add
            </Button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    );

  }
}

export default BaseplateAdd;